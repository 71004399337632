export const amLocales = {
  kelatiAdmin: "ቀላቲ አድሚን",

  url: {
    brandList: "የምርት ስም: ዝርዝር",
    mainCategoryList: "የዋና ምድብ: ዝርዝር",
    categoryList: "የምድብ: ዝርዝር",
    subCategoryList: "የንዑስ ምድብ: ዝርዝር",
    productList: "የምርት: ዝርዝር",
    inventoryList: "የመጋዘን: ዝርዝር",
    variationList: "የልዩነት: ዝርዝር",
    variationOptionList: "የልዩነት አማራጭ: ዝርዝር",
    userList: "የተጠቃሚ: ዝርዝር",
    groupList: "የቡድን: ዝርዝር",
    permissionList: "ፈቃድ: ዝርዝር",
    contactUsList: "ያግኙን: ዝርዝር",
    orderList: "የትዕዛዝ: ዝርዝር",
    dashboardList: "ዳሽቦርድ፡ መተግበሪያ",
    requestItemList: "የተጠየቁ ዕቃዎች: ዝርዝር",
  },

  sidebar: {
    general: "አጠቃላይ",
    dashboard: "ዳሽቦርድ",
    products: "ምርቶች",
    orders: "ትዕዛዞች",
    inventory: "መጋዘን",
    productManagement: "የምርት አስተዳደር",
    userManagement: "የተጠቃሚ አስተዳደር",
    brands: "ብራንዶች",
    mainCategories: "ዋና ምድብ",
    categories: "ምድብ",
    subCategories: "ንዑስ ምድብ",
    variations: "ልዩነቶች",
    variationOptions: "የልዩነት አማራጮች",
    users: "ተጠቃሚዎች",
    groups: "ቡድኖች",
    permissions: "ፈቃዶች",
    contactUs: "ያግኙን",
    requestedItems: "የተጠየቁ ዕቃዎች",
  },

  brand: {
    list: "የምርት ስም ዝርዝር",
    pluralize: "ብራንዶች",
    createBrand: "የምርት ስም ፍጠር",
    updateBrand: "የምርት ስም አዘምን",
    deleteBrand: "የምርት ስም ሰርዝ",
    searchBrand: "በምርት ስም ፈልግ",
    tableColumns: {
      brandName: "የምርት ስም",
      brandImage: "የምርት ስም ምስል",
      brandDescription: "የምርት ስም መግለጫ",
    },
  },

  contactUs: {
    list: "ያግኙን ዝርዝር",
    pluralize: "ያግኙን",
    searchContactUs: "በያግኙን ፈልግ",
    tableColumns: {
      fullName: "ሙሉ ስም",
      email: "ኢሜል",
      phone: "ስልክ",
      message: "መልዕክት",
    },
  },

  requestItem: {
    list: "የተጠየቁ ዕቃዎች ዝርዝር",
    pluralize: "የተጠየቁ ዕቃዎች",
    searchRequestItem: "በተጠየቁ ዕቃዎች ፈልግ",
    tableColumns: {
      productName: "ምርት ስም",
      phoneNumber: "ስልክ ቁጥር",
      email: "ኢሜል",
      productDescription: "ምርት መግለጫ",
      status: "ሁኔታ",
      requestItemImage: "የተጠየቁ ዕቃዎች ምስል",
    },
  },

  mainCategory: {
    list: "የዋና ምድብ ዝርዝር",
    pluralize: "ዋና ምድቦች",
    createMainCategory: "የዋና ምድብ ፍጠር",
    updateMainCategory: "የዋና ምድብ አዘምን",
    deleteMainCategory: "የዋና ምድብ ሰርዝ",
    searchMainCategory: "በዋና ምድብ ፈልግ",
    tableColumns: {
      mainCategoryName: "የዋና ምድብ ስም",
      mainCategoryImage: "የዋና ምድብ ምስል",
      mainCategoryDescription: "የዋና ምድብ መግለጫ",
    },
  },

  category: {
    list: "የምድብ ዝርዝር",
    pluralize: "ምድቦች",
    createCategory: "ምድብ ፍጠር",
    updateCategory: "የምድብ አዘምን",
    deleteCategory: "የምድብ ሰርዝ",
    searchCategory: "በምድብ ፈልግ",
    tableColumns: {
      categoryName: "የምድብ ስም",
      categoryImage: "የምድብ ምስል",
      categoryDescription: "የምድብ መግለጫ",
    },
  },

  subCategory: {
    list: "የንዑስ ምድብ ዝርዝር",
    pluralize: "ንዑስ ምድቦች",
    createSubCategory: "ንዑስ ምድብ ፍጠር",
    updateSubCategory: "የንዑስ ምድብ አዘምን",
    deleteSubCategory: "የንዑስ ምድብ ሰርዝ",
    searchSubCategory: "በንዑስ ምድብ ፈልግ",
    tableColumns: {
      subCategoryName: "የንዑስ ምድብ ስም",
      subCategoryImage: "የንዑስ ምድብ ምስል",
      subCategoryDescription: "የንዑስ ምድብ መግለጫ",
    },
  },

  product: {
    list: "የምርት ዝርዝር",
    pluralize: "ምርቶች",
    createProduct: "ምርት ፍጠር",
    updateProduct: "የምርት አዘምን",
    deleteProduct: "የምርት ሰርዝ",
    searchProduct: "በምርት ፈልግ",
    tableColumns: {
      productName: "የምርት ስም",
      productImage: "የምርት ምስል",
      productDescription: "የምርት መግለጫ",
      howToUse: "እንዴት ይጠቀሙ",
      ingredients: "ንጥረ ነገሮች",
    },
  },

  order: {
    list: "የትዕዛዝ ዝርዝር",
    pluralize: "ትዕዛዞች",
    searchOrder: "በትዕዛዝ ፈልግ",
    tableColumns: {
      orderNumber: "የትዕዛዝ ቁጥር",
      orderDate: "የትዕዛዝ ቀን",
      orderStatus: "የትዕዛዝ ሁኔታ",
      orderTotal: "ጠቅላላ ዋጋ",
      orderPaymentStatus: "የትዕዛዝ ክፍያ ሁኔታ",
      orderPaymentMethod: "የትዕዛዝ ክፍያ ዘዴ",
      orderCustomer: "የትዕዛዝ ሰራተኛ",
    },
  },

  inventory: {
    list: "የመጋዘን ምርቶች ዝርዝር",
    pluralize: "የመጋዘን ምርቶች",
    searchInventory: "በመጋዘን ምርቶች ፈልግ",
    tableColumns: {
      inventoryName: "የመጋዘን ስም",
      inventoryDescription: "የመጋዘን መግለጫ",
      unitPrice: "ዋጋ",
      totalStock: "የምርቶች ብዛት",
      totalPrice: "ጠቅላላ ዋጋ",
    },
  },

  variation: {
    list: "የልዩነት ዝርዝር",
    pluralize: "ልዩነቶች",
    createVariation: "ልዩነት ፍጠር",
    updateVariation: "የልዩነት አዘምን",
    deleteVariation: "የልዩነት ሰርዝ",
    searchVariation: "በልዩነት ፈልግ",
    tableColumns: {
      variationName: "የልዩነት ስም",
      variationDescription: "የልዩነት መግለጫ",
      variationOptions: "የልዩነት አማራጭ",
    },
  },

  variationOption: {
    list: "የልዩነት አማራጭ ዝርዝር",
    pluralize: "የልዩነት አማራጮች",
    createVariationOption: "የልዩነት አማራጭ ፍጠር",
    updateVariationOption: "የልዩነት አማራጭ አዘምን",
    deleteVariationOption: "የልዩነት አማራጭ ሰርዝ",
    searchVariationOption: "በልዩነት አማራጭ ፈልግ",
    tableColumns: {
      variationOptionName: "የልዩነት አማራጭ ስም",
      variationOptionDescription: "የልዩነት አማራጭ መግለጫ",
    },
  },

  user: {
    list: "የተጠቃሚ ዝርዝር",
    pluralize: "ተጠቃሚዎች",
    createUser: "ተጠቃሚ ፍጠር",
    updateUser: "የተጠቃሚ አዘምን",
    deleteUser: "የተጠቃሚ ሰርዝ",
    searchUser: "በተጠቃሚ ፈልግ",
    tableColumns: {
      userFullName: "የተጠቃሚ ሙሉ ስም",
      userName: "የተጠቃሚ ስም",
      userEmail: "የተጠቃሚ ኢሜል",
      userRole: "የተጠቃሚ ሚና",
      userPhoneNumber: "የተጠቃሚ ስልክ ቁጥር",
      userGroups: "የተጠቃሚ ቡድኖች",
      userStatus: "ሁኔታ",
    },
  },

  permissions: {
    list: "የፈቃዶች ዝርዝር",
    pluralize: "ፈቃዶች",
    searchPermission: "በፈቃድ ፈልግ",
    tableColumns: {
      permissionName: "የፈቃድ ስም",
      permissionDescription: "የፈቃድ መግለጫ",
    },
  },

  groups: {
    list: "ቡድኖች ዝርዝር",
    pluralize: "ቡድኖች",
    createGroup: "ቡድን ፍጠር",
    updateGroup: "የቡድን አዘምን",
    deleteGroup: "የቡድን ሰርዝ",
    searchGroup: "በቡድን ፈልግ",
    tableColumns: {
      groupName: "የቡድን ስም",
      groupOption: "የቡድን አማራጭ",
      groupDescription: "የቡድን መግለጫ",
      groupPermissions: "የቡድን ፈቃዶች",
    },
  },

  common: {
    dashboard: "ዳሽቦርድ",
    tableColumns: {
      slug: "ስሉግ",
      createdDate: "የተፈጠረበት ቀን",
      updatedDate: "የዘመነበት ቀን",
      featured: "ተለይቶ የቀረበ",
      actions: "ድርጊቶች",
    },
    searchBy: "ፈልግ በ",
  },
};
