import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../utils/axios";
import { checkError } from "../../../utils/checkError";

const INITIAL_STATE = {
  fetchMainCategoriesLoading: false,
  fetchMainCategoriesError: null,

  fetchMainCategoryLoading: false,
  fetchMainCategoryError: null,

  createMainCategoryLoading: false,
  createMainCategorySuccess: false,
  createMainCategoryError: null,

  updateMainCategoryLoading: false,
  updateMainCategorySuccess: false,
  updateMainCategoryError: null,

  deleteMainCategoryLoading: false,
  deleteMainCategorySuccess: false,
  deleteMainCategoryError: null,

  mainCategories: [],
  mainCategory: null,
  pagination: {
    previous: null,
    next: null,
    count: 0,
  },
};

export const fetchMainCategoriesAsync = createAsyncThunk(
  "mainCategory/fetchMainCategoriesAsync",
  async ({ all, page, payload }, { rejectWithValue }) => {
    try {
      const { data } = await api.get("/main-categories/", {
        params: !all
          ? {
              page,
              description__icontains: payload,
              name__icontains: payload,
            }
          : {
              all: true,
            },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchMainCategoryAsync = createAsyncThunk(
  "mainCategory/fetchMainCategoryAsync",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await api.get(`/main-categories/${payload}/`);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createMainCategoryAsync = createAsyncThunk(
  "mainCategory/createMainCategoryAsync",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await api.post("/main-categories/", payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateMainCategoryAsync = createAsyncThunk(
  "mainCategory/updateMainCategoryAsync",
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const { data } = await api.put(`/main-categories/${id}/`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteMainCategoryAsync = createAsyncThunk(
  "mainCategory/deleteMainCategoryAsync",
  async (payload, { rejectWithValue }) => {
    try {
      await api.delete(`/main-categories/${payload}`);
      return payload;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const mainCategorySlice = createSlice({
  name: "mainCategory",
  initialState: INITIAL_STATE,
  reducers: {
    clearCreateMainCategory: (state) => {
      state.createMainCategoryLoading = false;
      state.createMainCategorySuccess = false;
      state.createMainCategoryError = null;
    },
    clearUpdateMainCategory: (state) => {
      state.updateMainCategoryLoading = false;
      state.updateMainCategorySuccess = false;
      state.updateMainCategoryError = null;
    },
    clearDeleteMainCategory: (state) => {
      state.deleteMainCategoryLoading = false;
      state.deleteMainCategorySuccess = false;
      state.deleteMainCategoryError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMainCategoriesAsync.pending, (state) => {
        state.fetchMainCategoriesLoading = true;
      })
      .addCase(fetchMainCategoriesAsync.fulfilled, (state, action) => {
        const { results, previous, next, count } = action.payload;
        state.fetchMainCategoriesLoading = false;
        state.mainCategories = action.meta.arg.all ? action.payload : results;
        state.pagination = {
          previous,
          next,
          count,
        };
      })
      .addCase(fetchMainCategoriesAsync.rejected, (state, action) => {
        state.fetchMainCategoriesLoading = false;
        state.fetchMainCategoriesError = action.payload;
      })

      .addCase(fetchMainCategoryAsync.pending, (state) => {
        state.fetchMainCategoryLoading = true;
      })
      .addCase(fetchMainCategoryAsync.fulfilled, (state, action) => {
        state.fetchMainCategoryLoading = false;
        state.mainCategory = action.payload;
      })
      .addCase(fetchMainCategoryAsync.rejected, (state, action) => {
        state.fetchMainCategoryLoading = false;
        state.fetchMainCategoryError = action.payload;
      })

      .addCase(createMainCategoryAsync.pending, (state) => {
        state.createMainCategoryLoading = true;
      })
      .addCase(createMainCategoryAsync.fulfilled, (state, action) => {
        state.createMainCategoryLoading = false;
        state.createMainCategorySuccess = true;
        state.mainCategories.unshift(action.payload);
        state.pagination.count += 1;
      })
      .addCase(createMainCategoryAsync.rejected, (state, action) => {
        const { response } = action.payload;
        state.createMainCategoryLoading = false;
        state.createMainCategoryError = checkError(response);
      })

      .addCase(updateMainCategoryAsync.pending, (state) => {
        state.updateMainCategoryLoading = true;
      })
      .addCase(updateMainCategoryAsync.fulfilled, (state, action) => {
        state.updateMainCategoryLoading = false;
        state.updateMainCategorySuccess = true;
        state.mainCategories = state.mainCategories.map((mainCategory) =>
          mainCategory.id === action.payload.id ? action.payload : mainCategory
        );
      })
      .addCase(updateMainCategoryAsync.rejected, (state, action) => {
        const { response } = action.payload;
        state.updateMainCategoryLoading = false;
        state.updateMainCategoryError = checkError(response);
      })

      .addCase(deleteMainCategoryAsync.pending, (state) => {
        state.deleteMainCategoryLoading = true;
      })
      .addCase(deleteMainCategoryAsync.fulfilled, (state, action) => {
        state.deleteMainCategoryLoading = false;
        state.deleteMainCategorySuccess = true;
        state.mainCategories = state.mainCategories.filter(
          (mainCategory) => mainCategory.id !== action.meta.arg
        );
        state.pagination.count -= 1;
      })
      .addCase(deleteMainCategoryAsync.rejected, (state, action) => {
        const { response, config } = action.payload;
        state.deleteMainCategoryLoading = false;
        state.deleteMainCategoryError = checkError(
          response,
          config,
          "main category"
        );
      });
  },
});

export const {
  clearCreateMainCategory,
  clearUpdateMainCategory,
  clearDeleteMainCategory,
} = mainCategorySlice.actions;
export default mainCategorySlice;
