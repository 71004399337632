import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../utils/axios";
import { checkError } from "../../../utils/checkError";

const INITIAL_STATE = {
  fetchProductsLoading: false,
  fetchProductsError: null,

  fetchProductLoading: false,
  fetchProductError: null,

  createProductLoading: false,
  createProductSuccess: false,
  createProductError: null,

  createProductCombinationLoading: false,
  createProductCombinationSuccess: false,
  createProductCombinationError: null,

  updateProductLoading: false,
  updateProductSuccess: false,
  updateProductError: null,

  deleteProductLoading: false,
  deleteProductSuccess: false,
  deleteProductError: null,

  products: [],
  product: null,
  pagination: {
    count: 0,
    previous: null,
    next: null,
  },
};

export const fetchProductsAsync = createAsyncThunk(
  "product/fetchProductsAsync",
  async ({ page, payload }, { rejectWithValue }) => {
    try {
      const { data } = await api.get("/products/", {
        params: {
          page,
          description__icontains: payload,
          name__icontains: payload,
        },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchProductAsync = createAsyncThunk(
  "product/fetchProductAsync",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await api.get(`/products/${id}/`);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createProductAsync = createAsyncThunk(
  "product/createProductAsync",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await api.post("/products/", payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createProductCombinationAsync = createAsyncThunk(
  "product/createProductCombinationAsync",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await api.post("/product-combinations/", payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateProductAsync = createAsyncThunk(
  "product/updateProductAsync",
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const { data } = await api.put(`/products/${id}/`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateIsFeaturedOnProductAsync = createAsyncThunk(
  "product/updateIsFeaturedOnProductAsync",
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const { data } = await api.patch(`/products/${id}/`, payload);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteProductAsync = createAsyncThunk(
  "product/deleteProductAsync",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await api.delete(`/products/${payload}/`);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const productSlice = createSlice({
  name: "product",
  initialState: INITIAL_STATE,
  reducers: {
    clearCreateProduct: (state) => {
      state.createProductLoading = false;
      state.createProductSuccess = false;
      state.createProductError = null;
    },
    clearCreateProductCombination: (state) => {
      state.createProductCombinationLoading = false;
      state.createProductCombinationSuccess = false;
      state.createProductCombinationError = null;
    },
    clearUpdateProduct: (state) => {
      state.updateProductLoading = false;
      state.updateProductSuccess = false;
      state.updateProductError = null;
    },
    clearDeleteProduct: (state) => {
      state.deleteProductLoading = false;
      state.deleteProductSuccess = false;
      state.deleteProductError = null;
    },
    setSelectedProductG: (state, { payload }) => {
      state.product = payload;
    },
    clearSelectedProductG: (state) => {
      state.product = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductsAsync.pending, (state) => {
        state.fetchProductsLoading = true;
      })
      .addCase(fetchProductsAsync.fulfilled, (state, action) => {
        const { results, previous, next, count } = action.payload;
        state.fetchProductsLoading = false;
        state.products = results;
        state.pagination = {
          previous,
          next,
          count,
        };
      })
      .addCase(fetchProductsAsync.rejected, (state, { payload }) => {
        state.fetchProductsLoading = false;
        state.fetchProductsError = payload;
      })

      .addCase(fetchProductAsync.pending, (state) => {
        state.fetchProductLoading = true;
      })
      .addCase(fetchProductAsync.fulfilled, (state, { payload }) => {
        state.fetchProductLoading = false;
        state.product = payload;
      })
      .addCase(fetchProductAsync.rejected, (state, { payload }) => {
        state.fetchProductLoading = false;
        state.fetchProductError = payload;
      })

      .addCase(createProductAsync.pending, (state) => {
        state.createProductLoading = true;
      })
      .addCase(createProductAsync.fulfilled, (state, action) => {
        state.createProductLoading = false;
        state.createProductSuccess = true;
        state.products.unshift(action.payload);
        state.pagination.count += 1;
      })
      .addCase(createProductAsync.rejected, (state, action) => {
        const { response } = action.payload;
        state.createProductLoading = false;
        state.createProductError = checkError(response);
      })

      .addCase(createProductCombinationAsync.pending, (state) => {
        state.createProductCombinationLoading = true;
      })
      .addCase(
        createProductCombinationAsync.fulfilled,
        (state, { payload }) => {
          state.createProductCombinationLoading = false;
          state.createProductCombinationSuccess = true;
        }
      )
      .addCase(createProductCombinationAsync.rejected, (state, action) => {
        const { response } = action.payload;
        state.createProductCombinationLoading = false;
        state.createProductCombinationError = checkError(response);
      })

      .addCase(updateProductAsync.pending, (state) => {
        state.updateProductLoading = true;
      })
      .addCase(updateProductAsync.fulfilled, (state, { payload }) => {
        state.updateProductLoading = false;
        state.updateProductSuccess = true;
        state.products = state.products.map((product) =>
          product.id === payload.id ? payload : product
        );
      })
      .addCase(updateProductAsync.rejected, (state, action) => {
        const { response } = action.payload;
        state.updateProductLoading = false;
        state.updateProductError = checkError(response);
      })

      .addCase(updateIsFeaturedOnProductAsync.pending, (state) => {
        state.updateProductLoading = true;
      })
      .addCase(
        updateIsFeaturedOnProductAsync.fulfilled,
        (state, { payload }) => {
          state.updateProductLoading = false;
          state.updateProductSuccess = true;
          state.products = state.products.map((product) =>
            product.id === payload.id ? payload : product
          );
        }
      )
      .addCase(
        updateIsFeaturedOnProductAsync.rejected,
        (state, { payload }) => {
          state.updateProductLoading = false;
          state.updateProductError = payload;
        }
      )

      .addCase(deleteProductAsync.pending, (state) => {
        state.deleteProductLoading = true;
      })
      .addCase(deleteProductAsync.fulfilled, (state, action) => {
        state.deleteProductLoading = false;
        state.deleteProductSuccess = true;
        state.products = state.products.filter(
          (product) => product.id !== action.meta.arg
        );
        state.pagination.count -= 1;
      })
      .addCase(deleteProductAsync.rejected, (state, { payload }) => {
        state.deleteProductLoading = false;
        state.deleteProductError = payload;
      });
  },
});

export const {
  clearCreateProduct,
  clearCreateProductCombination,
  clearUpdateProduct,
  clearDeleteProduct,
  setSelectedProductG,
  clearSelectedProductG,
} = productSlice.actions;
export default productSlice;
