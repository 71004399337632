import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../utils/axios";

const INITIAL_STATE = {
  fetchInventoryStatLoading: false,
  fetchInventoryStatError: null,

  fetchDashboardStatLoading: false,
  fetchDashboardStatError: null,

  inventoryStats: {},
  dashboardStats: {
    products: {
      count: 0,
      percentage: "0",
      by_week: [],
    },
    users: {
      count: 0,
      percentage: "0",
      by_week: [],
    },
    orders: {
      count: 0,
      percentage: "0",
      by_week: [],
    },
    recent_users: [],
    featured_products: [],
    orderChartData: [
      {
        label: "Quantity",
        data: [
          {
            name: "All",
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
        ],
      },
      {
        label: "Amount",
        data: [
          {
            name: "All",
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
        ],
      },
    ],
    mccscb: [],
    order_status: [],
    order_chart_data: [],
    recent_orders: [],
    total_orders_price: 0,
  },
};

export const fetchInventoryStatAsync = createAsyncThunk(
  "stat/fetchInventoryStatAsync",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get("/inventory-stats/");
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchDashboardStatAsync = createAsyncThunk(
  "stat/fetchDashboardStatAsync",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get("/dashboard-stats/");
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const statSlice = createSlice({
  name: "stat",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInventoryStatAsync.pending, (state) => {
        state.fetchInventoryStatLoading = true;
        state.fetchInventoryStatError = null;
      })
      .addCase(fetchInventoryStatAsync.fulfilled, (state, action) => {
        state.fetchInventoryStatLoading = false;
        state.fetchInventoryStatError = null;
        state.inventoryStats = action.payload;
      })
      .addCase(fetchInventoryStatAsync.rejected, (state, action) => {
        state.fetchInventoryStatLoading = false;
        state.fetchInventoryStatError = action.payload;
      })

      .addCase(fetchDashboardStatAsync.pending, (state) => {
        state.fetchDashboardStatLoading = true;
        state.fetchDashboardStatError = null;
      })
      .addCase(fetchDashboardStatAsync.fulfilled, (state, action) => {
        state.fetchDashboardStatLoading = false;
        state.fetchDashboardStatError = null;
        state.dashboardStats = { ...state.dashboardStats, ...action.payload };
      })
      .addCase(fetchDashboardStatAsync.rejected, (state, action) => {
        state.fetchDashboardStatLoading = false;
        state.fetchDashboardStatError = action.payload;
      });
  },
});

export default statSlice;
