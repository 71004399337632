import { sentenceCase } from "change-case";

export const checkError = (response, config = null, moduleName = "") => {
  if (!response) {
    return "Something went wrong";
  }

  if (config !== null)
    if (config.method === "delete" && response.status === 500) {
      return `Failed to delete ${moduleName} because it is being used. Delete the related data first.`;
    }

  if (response.error) {
    return response.error;
  }

  if (config) {
    if (
      response.data &&
      config.url === "/auth/jwt/signin/" &&
      response.status === 400
    ) {
      return sentenceCase(response.data[0]);
    }
  }

  if (response.data !== undefined) {
    if (response.data.name) {
      return sentenceCase(response.data.name[0]);
    }
    if (response.data.value) {
      return sentenceCase(response.data.value[0]);
    }
    if (response.data.sku) {
      return sentenceCase(response.data.sku[0]);
    }
  }

  return "Error from CheckError";
};
