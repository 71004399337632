import { MAvatar } from "./@material-extend";
import createAvatar from "../utils/createAvatar";
import { useSelector } from "react-redux";

export default function MyAvatar({ ...other }) {
  const { user } = useSelector((state) => state.user);
  const checkUser = user ? user.full_name : "";
  return (
    <MAvatar
      src={"user.photoURL"}
      alt={checkUser}
      color={checkUser !== "" ? "default" : createAvatar(checkUser).color}
      {...other}
    >
      {createAvatar(checkUser).name}
    </MAvatar>
  );
}
