import { combineReducers } from "@reduxjs/toolkit";
import brandSlice from "./features/brand/brand.slice";
import storage from "redux-persist/lib/storage";
import authSlice from "./features/auth/auth.slice";
import { persistReducer } from "redux-persist";
import userSlice from "./features/user/user.slice";
import mainCategorySlice from "./features/main-category/main-category.slice";
import categorySlice from "./features/category/category.slice";
import subCategorySlice from "./features/sub-category/sub-category.slice";
import productSlice from "./features/product/product.slice";
import variationSlice from "./features/variation/variation.slice";
import variationOptionSlice from "./features/variation-option/variation-option.slice";
import productVariationOptionSlice from "./features/product-variation-option/product-variation-option.slice";
import productVariationOptionValueSlice from "./features/product-variation-option-values/product-variation-option-value.slice";
import productCombinationSlice from "./features/product-combination/product-combination.slice";
import productStockSlice from "./features/product-stock/product-stock.slice";
import permissionSlice from "./features/permission/permission.slice";
import groupSlice from "./features/group/group.slice";
import statSlice from "./features/stat/stat.slice";
import contactusSlice from "./features/contact-us/contact-us.slice";
import orderSlice from "./features/order/order.slice";
import { encryptTransform } from "redux-persist-transform-encrypt";
import requestItemSlice from "./features/request-item/request-item.slice";

const encryptor = encryptTransform({
  secretKey: process.env.REACT_APP_ENCRYPT_TRANSFORM_KEY,
  onError: function (error) {
    // Handle the error.
  },
});

const encryptorObj = {
  transforms: [encryptor],
};

let authPersistConfig = {
  key: "auth",
  storage,
  whitelist: ["accessToken", "refreshToken"],
};

if (process.env.NODE_ENV === "production")
  authPersistConfig = {
    ...authPersistConfig,
    ...encryptorObj,
  };

export const rootReducer = combineReducers({
  [authSlice.name]: persistReducer(authPersistConfig, authSlice.reducer),
  [brandSlice.name]: brandSlice.reducer,
  [contactusSlice.name]: contactusSlice.reducer,
  [groupSlice.name]: groupSlice.reducer,
  [userSlice.name]: userSlice.reducer,
  [mainCategorySlice.name]: mainCategorySlice.reducer,
  [categorySlice.name]: categorySlice.reducer,
  [subCategorySlice.name]: subCategorySlice.reducer,
  [orderSlice.name]: orderSlice.reducer,
  [permissionSlice.name]: permissionSlice.reducer,
  [productSlice.name]: productSlice.reducer,
  [productCombinationSlice.name]: productCombinationSlice.reducer,
  [productStockSlice.name]: productStockSlice.reducer,
  [productVariationOptionSlice.name]: productVariationOptionSlice.reducer,
  [productVariationOptionValueSlice.name]:
    productVariationOptionValueSlice.reducer,
  [requestItemSlice.name]: requestItemSlice.reducer,
  [statSlice.name]: statSlice.reducer,
  [variationSlice.name]: variationSlice.reducer,
  [variationOptionSlice.name]: variationOptionSlice.reducer,
});
