import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../utils/axios";
import { checkError } from "../../../utils/checkError";

const INITIAL_STATE = {
  fetchRequestItemsLoading: false,
  fetchRequestItemsError: null,

  updateRequestItemLoading: false,
  updateRequestItemSuccess: false,
  updateRequestItemError: null,

  deleteRequestItemLoading: false,
  deleteRequestItemSuccess: false,
  deleteRequestItemError: null,

  requestItems: [],
  pagination: {
    previous: null,
    next: null,
    count: 0,
  },
};

export const fetchRequestItemsAsync = createAsyncThunk(
  "requestItem/fetchRequestItemsAsync",
  async ({ all, page, payload }, { rejectWithValue }) => {
    try {
      const { data } = await api.get("/request-items/", {
        params: !all
          ? {
              page: page,
              product_name__icontains: payload,
              product_description__icontains: payload,
            }
          : {
              all: true,
            },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateRequestItemAsync = createAsyncThunk(
  "requestItem/updateRequestItemAsync",
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const { data } = await api.patch(`/request-items/${id}/`, payload);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteRequestItemAsync = createAsyncThunk(
  "requestItem/deleteRequestItemAsync",
  async (payload, { rejectWithValue }) => {
    try {
      await api.delete(`/request-items/${payload}`);
      return payload;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const requestItemSlice = createSlice({
  name: "requestItem",
  initialState: INITIAL_STATE,
  reducers: {
    clearUpdateRequestItem: (state) => {
      state.updateRequestItemLoading = false;
      state.updateRequestItemSuccess = false;
      state.updateRequestItemError = null;
    },
    clearDeleteRequestItem: (state) => {
      state.deleteRequestItemLoading = false;
      state.deleteRequestItemSuccess = false;
      state.deleteRequestItemError = null;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchRequestItemsAsync.pending, (state) => {
        state.fetchRequestItemsLoading = true;
        state.fetchRequestItemsError = null;
      })
      .addCase(fetchRequestItemsAsync.fulfilled, (state, action) => {
        const { results, previous, next, count } = action.payload;
        state.fetchRequestItemsLoading = false;
        state.fetchRequestItemsError = null;
        state.requestItems = action.meta.arg.all ? action.payload : results;
        state.pagination = {
          previous,
          next,
          count,
        };
      })
      .addCase(fetchRequestItemsAsync.rejected, (state, { payload }) => {
        state.fetchRequestItemsLoading = false;
        state.fetchRequestItemsError = payload;
      })
      .addCase(updateRequestItemAsync.pending, (state) => {
        state.updateRequestItemLoading = true;
        state.updateRequestItemSuccess = false;
        state.updateRequestItemError = null;
      })
      .addCase(updateRequestItemAsync.fulfilled, (state, action) => {
        state.updateRequestItemLoading = false;
        state.updateRequestItemSuccess = true;
        state.updateRequestItemError = null;
        state.requestItems = state.requestItems.map((requestItem) => {
          if (requestItem.id === action.payload.id) {
            return {
              ...requestItem,
              ...action.payload.id,
            };
          }
          return requestItem;
        });
      })
      .addCase(updateRequestItemAsync.rejected, (state, action) => {
        const { response } = action.payload;

        state.updateRequestItemLoading = false;
        state.updateRequestItemSuccess = false;
        state.updateRequestItemError = checkError(response);
      })
      .addCase(deleteRequestItemAsync.pending, (state) => {
        state.deleteRequestItemLoading = true;
        state.deleteRequestItemSuccess = false;
        state.deleteRequestItemError = null;
      })
      .addCase(deleteRequestItemAsync.fulfilled, (state, { payload, meta }) => {
        state.deleteRequestItemLoading = false;
        state.deleteRequestItemSuccess = true;
        state.deleteRequestItemError = null;
        state.requestItems = state.requestItems.filter(
          (requestItem) => requestItem.id !== meta.arg
        );
        state.pagination.count -= 1;
      })
      .addCase(deleteRequestItemAsync.rejected, (state, action) => {
        const { response } = action.payload;
        state.deleteRequestItemLoading = false;
        state.deleteRequestItemSuccess = false;
        state.deleteRequestItemError = checkError(response);
      });
  },
});

export const { clearUpdateRequestItem, clearDeleteRequestItem } =
  requestItemSlice.actions;
export default requestItemSlice;
