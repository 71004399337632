import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../utils/axios";
import { checkError } from "../../../utils/checkError";

const INITIAL_STATE = {
  fetchUsersLoading: false,
  fetchUsersError: null,

  fetchUserLoading: false,
  fetchUserError: null,

  createUserLoading: false,
  createUserSuccess: false,
  createUserError: null,

  updateUserLoading: false,
  updateUserSuccess: false,
  updateUserError: null,

  deleteUserLoading: false,
  deleteUserSuccess: false,
  deleteUserError: null,

  assignUserToGroupLoading: false,
  assignUserToGroupSuccess: false,
  assignUserToGroupError: null,

  deleteUserToGroupLoading: false,
  deleteUserToGroupSuccess: false,
  deleteUserToGroupError: null,

  user: null,
  selectedUser: null,
  users: [],
  pagination: {
    count: 0,
    previous: null,
    next: null,
  },
};

export const fetchUsersAsync = createAsyncThunk(
  "user/fetchUsers",
  async ({ all, page, payload }, { rejectWithValue }) => {
    try {
      const response = await api.get("/auth/users/", {
        params: !all
          ? {
              page,
            }
          : {
              all: true,
            },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchDeliverersAsync = createAsyncThunk(
  "user/fetchDeliverers",
  async ({ all }, { rejectWithValue }) => {
    try {
      const response = await api.get("/auth/users/deliverers/", {
        params: {
          all,
        },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchAdminsAsync = createAsyncThunk(
  "user/fetchAdmins",
  async ({ all }, { rejectWithValue }) => {
    try {
      const response = await api.get("/auth/users/admins/", {
        params: {
          all,
        },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchUserAsync = createAsyncThunk(
  "user/fetchUser",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get("/auth/users/me/");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createUserAsync = createAsyncThunk(
  "user/createUser",
  async (userPayload, { rejectWithValue }) => {
    try {
      const response = await api.post("/auth/jwt/signup/", userPayload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateUserAsync = createAsyncThunk(
  "user/updateUser",
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const response = await api.patch(`/auth/users/${id}/`, payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteUserAsync = createAsyncThunk(
  "user/deleteUser",
  async ({ id, password }, { rejectWithValue }) => {
    try {
      await api.delete(`/auth/users/${id}`, {
        data: {
          current_password: password,
        },
      });

      return id;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const assignUserToGroupAsync = createAsyncThunk(
  "user/assignUserToGroup",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.post(`/user-groups/`, payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteUserToGroupAsync = createAsyncThunk(
  "user/deleteUserToGroup",
  async ({ id, user_id }, { rejectWithValue }) => {
    try {
      await api.delete(`/user-groups/${id}/`, {
        data: {
          user_id,
        },
      });
      return id;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: INITIAL_STATE,
  reducers: {
    clearCreateUser: (state) => {
      state.createUserLoading = false;
      state.createUserSuccess = false;
      state.createUserError = null;
    },

    clearUpdateUser: (state) => {
      state.updateUserLoading = false;
      state.updateUserSuccess = false;
      state.updateUserError = null;
    },

    clearDeleteUser: (state) => {
      state.deleteUserLoading = false;
      state.deleteUserSuccess = false;
      state.deleteUserError = null;
    },

    clearAssignUserToGroup: (state) => {
      state.assignUserToGroupLoading = false;
      state.assignUserToGroupSuccess = false;
      state.assignUserToGroupError = null;
    },

    clearDeleteUserToGroup: (state) => {
      state.deleteUserToGroupLoading = false;
      state.deleteUserToGroupSuccess = false;
      state.deleteUserToGroupError = null;
    },

    setSelectedUserG: (state, action) => {
      state.selectedUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsersAsync.pending, (state, action) => {
        state.fetchUsersLoading = true;
      })
      .addCase(fetchUsersAsync.fulfilled, (state, action) => {
        const { results, count, previous, next } = action.payload;
        state.fetchUsersLoading = false;
        state.users = results;
        state.pagination = {
          count,
          previous,
          next,
        };
      })
      .addCase(fetchUsersAsync.rejected, (state, action) => {
        state.fetchUsersLoading = false;
        state.fetchUsersError = action.payload;
      })

      .addCase(fetchDeliverersAsync.pending, (state, action) => {
        state.fetchUsersLoading = true;
      })
      .addCase(fetchDeliverersAsync.fulfilled, (state, action) => {
        state.fetchUsersLoading = false;
        state.users = action.payload;
      })
      .addCase(fetchDeliverersAsync.rejected, (state, action) => {
        state.fetchUsersLoading = false;
        state.fetchUsersError = action.payload;
      })

      .addCase(fetchAdminsAsync.pending, (state, action) => {
        state.fetchUsersLoading = true;
      })
      .addCase(fetchAdminsAsync.fulfilled, (state, action) => {
        state.fetchUsersLoading = false;
        state.users = action.payload;
      })
      .addCase(fetchAdminsAsync.rejected, (state, action) => {
        state.fetchUsersLoading = false;
        state.fetchUsersError = action.payload;
      })

      .addCase(fetchUserAsync.pending, (state, action) => {
        state.fetchUserLoading = true;
      })
      .addCase(fetchUserAsync.fulfilled, (state, action) => {
        state.fetchUserLoading = false;
        state.user = action.payload;
      })
      .addCase(fetchUserAsync.rejected, (state, action) => {
        state.fetchUserLoading = false;
        state.fetchUserError = action.payload;
      })

      .addCase(createUserAsync.pending, (state, action) => {
        state.createUserLoading = true;
      })
      .addCase(createUserAsync.fulfilled, (state, action) => {
        state.createUserLoading = false;
        state.createUserSuccess = true;
        state.pagination.count += 1;
      })
      .addCase(createUserAsync.rejected, (state, action) => {
        const { response } = action.payload;
        state.createUserLoading = false;
        state.createUserError = checkError(response);
      })

      .addCase(updateUserAsync.pending, (state, action) => {
        state.updateUserLoading = true;
      })
      .addCase(updateUserAsync.fulfilled, (state, action) => {
        state.updateUserLoading = false;
        state.updateUserSuccess = true;
        state.users = state.users.map((user) =>
          user.id === action.payload.id ? action.payload : user
        );
      })
      .addCase(updateUserAsync.rejected, (state, action) => {
        const { response } = action.payload;
        state.updateUserLoading = false;
        state.updateUserError = checkError(response);
      })

      .addCase(assignUserToGroupAsync.pending, (state, action) => {
        state.assignUserToGroupLoading = true;
      })
      .addCase(assignUserToGroupAsync.fulfilled, (state, action) => {
        state.assignUserToGroupLoading = false;
        state.assignUserToGroupSuccess = true;
      })
      .addCase(assignUserToGroupAsync.rejected, (state, action) => {
        state.assignUserToGroupLoading = false;
        state.assignUserToGroupError = action.payload;
      })

      .addCase(deleteUserAsync.pending, (state, action) => {
        state.deleteUserLoading = true;
      })
      .addCase(deleteUserAsync.fulfilled, (state, action) => {
        state.deleteUserLoading = false;
        state.deleteUserSuccess = true;
        state.users = state.users.filter(
          (user) => user.id !== action.meta.arg.id
        );
        state.pagination.count -= 1;
      })
      .addCase(deleteUserAsync.rejected, (state, action) => {
        state.deleteUserLoading = false;
        if (action.payload?.response?.data?.current_password) {
          state.deleteUserError = action.payload.response.data.current_password;
          return;
        }
        state.deleteUserError = action.payload;
      })

      .addCase(deleteUserToGroupAsync.pending, (state, action) => {
        state.deleteUserToGroupLoading = true;
      })
      .addCase(deleteUserToGroupAsync.fulfilled, (state, action) => {
        state.deleteUserToGroupLoading = false;
        state.deleteUserToGroupSuccess = true;

        state.selectedUser = {
          ...state.selectedUser,
          groups: state.selectedUser.groups.filter(
            (group) => group.id !== action.meta.arg.id
          ),
        };
      })
      .addCase(deleteUserToGroupAsync.rejected, (state, action) => {
        const { response, config } = action.payload;
        state.deleteUserToGroupLoading = false;
        state.deleteUserToGroupError = checkError(response, config);
      });
  },
});

export const {
  clearCreateUser,
  clearDeleteUser,
  clearUpdateUser,
  clearAssignUserToGroup,
  clearDeleteUserToGroup,
  setSelectedUserG,
} = userSlice.actions;
export default userSlice;
