import { PATH_DASHBOARD } from "../../routes/paths";
import SvgIconStyle from "../../components/SvgIconStyle";

const getIcon = (name) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: "100%", height: "100%" }}
  />
);

const ICONS = {
  brand: getIcon("ic_brand"),
  dashboard: getIcon("ic_dashboard"),
  contactUs: getIcon("ic_contact_us"),
  inventory: getIcon("ic_inventory"),
  mainCategories: getIcon("ic_main_categories"),
  categories: getIcon("ic_categories"),
  subCategories: getIcon("ic_sub_categories"),
  products: getIcon("ic_products"),
  orders: getIcon("ic_orders"),
  variations: getIcon("ic_variations"),
  variation_options: getIcon("ic_variation_options"),
  users: getIcon("ic_users"),
  groups: getIcon("ic_groups"),
  permissions: getIcon("ic_permissions"),
  requestItems: getIcon("ic_request_item"),
};

const sidebarConfig = [
  {
    subheader: "general",
    translate: "sidebar.general",
    groups: ["all"],
    items: [
      {
        title: "dashboard",
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard,
        translate: "sidebar.dashboard",
        group: "all",
      },
      {
        title: "products",
        path: PATH_DASHBOARD.general.products,
        icon: ICONS.products,
        translate: "sidebar.products",
        group: "product_admin",
      },
      {
        title: "orders",
        path: PATH_DASHBOARD.general.orders,
        icon: ICONS.orders,
        translate: "sidebar.orders",
        group: "order_admin",
      },
      {
        title: "inventory",
        path: PATH_DASHBOARD.general.inventory,
        icon: ICONS.inventory,
        translate: "sidebar.inventory",
        group: "inventory_admin",
      },
      {
        title: "users",
        path: PATH_DASHBOARD.general.users,
        icon: ICONS.users,
        translate: "sidebar.users",
        group: "user_admin",
      },
      {
        title: "contact us",
        path: PATH_DASHBOARD.general.contactUs,
        icon: ICONS.contactUs,
        translate: "sidebar.contactUs",
        group: "all",
      },
      {
        title: "requested items",
        path: PATH_DASHBOARD.general.requestItems,
        icon: ICONS.requestItems,
        translate: "sidebar.requestedItems",
        group: "all",
      },
    ],
  },
  {
    subheader: "management",
    translate: "sidebar.productManagement",
    groups: [
      "product_admin",
      "brand_admin",
      "main_category_admin",
      "category_admin",
      "sub_category_admin",
      "variation_admin",
    ],
    items: [
      {
        title: "brands",
        path: PATH_DASHBOARD.general.brands,
        icon: ICONS.brand,
        translate: "sidebar.brands",
        group: "brand_admin",
      },
      {
        title: "main categories",
        path: PATH_DASHBOARD.general.mainCategories,
        icon: ICONS.mainCategories,
        translate: "sidebar.mainCategories",
        group: "main_category_admin",
      },
      {
        title: "categories",
        path: PATH_DASHBOARD.general.categories,
        icon: ICONS.categories,
        translate: "sidebar.categories",
        group: "category_admin",
      },
      {
        title: "sub categories",
        path: PATH_DASHBOARD.general.subCategories,
        icon: ICONS.subCategories,
        translate: "sidebar.subCategories",
        group: "sub_category_admin",
      },
      {
        title: "variations",
        path: PATH_DASHBOARD.general.variations,
        icon: ICONS.variations,
        translate: "sidebar.variations",
        group: "variation_admin",
      },
      {
        title: "variation options",
        path: PATH_DASHBOARD.general.variation_options,
        icon: ICONS.variation_options,
        translate: "sidebar.variationOptions",
        group: "variation_admin",
      },
    ],
  },
  {
    subheader: "userManagement",
    translate: "sidebar.userManagement",
    groups: ["user_admin"],
    items: [
      {
        title: "groups",
        path: PATH_DASHBOARD.general.groups,
        icon: ICONS.groups,
        translate: "sidebar.groups",
        group: "user_admin",
      },
      {
        title: "permissions",
        path: PATH_DASHBOARD.general.permissions,
        icon: ICONS.permissions,
        translate: "sidebar.permissions",
        group: "user_admin",
      },
    ],
  },
];

export default sidebarConfig;
