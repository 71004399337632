import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../utils/axios";
import { checkError } from "../../../utils/checkError";

const INITAL_STATE = {
  fetchCategoriesLoading: false,
  fetchCategoriesError: null,

  fetchCategoryLoading: false,
  fetchCategoryError: null,

  createCategoryLoading: false,
  createCategorySuccess: false,
  createCategoryError: null,

  updateCategoryLoading: false,
  updateCategorySuccess: false,
  updateCategoryError: null,

  deleteCategoryLoading: false,
  deleteCategorySuccess: false,
  deleteCategoryError: null,

  categories: [],
  category: null,
  pagination: {
    previous: null,
    next: null,
    count: 0,
  },
};

export const fetchCategoriesAsync = createAsyncThunk(
  "category/fetchCategoriesAsync",
  async ({ all, page, payload }, { rejectWithValue }) => {
    try {
      const { data } = await api.get("/categories/", {
        params: !all
          ? {
              page,
              description__icontains: payload,
              name__icontains: payload,
              main_category__name__icontains: payload,
            }
          : {
              all: true,
            },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchCategoryAsync = createAsyncThunk(
  "category/fetchCategoryAsync",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await api.get(`/categories/${payload}/`);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createCategoryAsync = createAsyncThunk(
  "category/createCategoryAsync",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await api.post("/categories/", payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateCategoryAsync = createAsyncThunk(
  "category/updateCategoryAsync",
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const { data } = await api.put(`/categories/${id}/`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteCategoryAsync = createAsyncThunk(
  "category/deleteCategoryAsync",
  async (payload, { rejectWithValue }) => {
    try {
      await api.delete(`/categories/${payload}`);
      return payload;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const categorySlice = createSlice({
  name: "category",
  initialState: INITAL_STATE,
  reducers: {
    clearCreateCategory: (state) => {
      state.createCategoryLoading = false;
      state.createCategorySuccess = false;
      state.createCategoryError = null;
    },
    clearUpdateCategory: (state) => {
      state.updateCategoryLoading = false;
      state.updateCategorySuccess = false;
      state.updateCategoryError = null;
    },
    clearDeleteCategory: (state) => {
      state.deleteCategoryLoading = false;
      state.deleteCategorySuccess = false;
      state.deleteCategoryError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategoriesAsync.pending, (state, action) => {
        state.fetchCategoriesLoading = true;
      })
      .addCase(fetchCategoriesAsync.fulfilled, (state, action) => {
        const { results, previous, next, count } = action.payload;
        state.fetchCategoriesLoading = false;
        state.categories = action.meta.arg.all ? action.payload : results;
        state.pagination = {
          previous,
          next,
          count,
        };
      })
      .addCase(fetchCategoriesAsync.rejected, (state, action) => {
        state.fetchCategoriesLoading = false;
        state.fetchCategoriesError = action.payload;
      })

      .addCase(fetchCategoryAsync.pending, (state, action) => {
        state.fetchCategoryLoading = true;
      })
      .addCase(fetchCategoryAsync.fulfilled, (state, action) => {
        state.fetchCategoryLoading = false;
        state.category = action.payload;
      })
      .addCase(fetchCategoryAsync.rejected, (state, action) => {
        state.fetchCategoryLoading = false;
        state.fetchCategoryError = action.payload;
      })

      .addCase(createCategoryAsync.pending, (state, action) => {
        state.createCategoryLoading = true;
      })
      .addCase(createCategoryAsync.fulfilled, (state, action) => {
        state.createCategoryLoading = false;
        state.createCategorySuccess = true;
        state.categories.unshift(action.payload);
        state.pagination.count += 1;
      })
      .addCase(createCategoryAsync.rejected, (state, action) => {
        const { response } = action.payload;
        state.createCategoryLoading = false;
        state.createCategoryError = checkError(response);
      })

      .addCase(updateCategoryAsync.pending, (state, action) => {
        state.updateCategoryLoading = true;
      })
      .addCase(updateCategoryAsync.fulfilled, (state, action) => {
        state.updateCategoryLoading = false;
        state.updateCategorySuccess = true;
        state.categories = state.categories.map((category) => {
          if (category.id === action.payload.id) {
            return action.payload;
          }
          return category;
        });
      })
      .addCase(updateCategoryAsync.rejected, (state, action) => {
        const { response } = action.payload;
        state.updateCategoryLoading = false;
        state.updateCategoryError = checkError(response);
      })

      .addCase(deleteCategoryAsync.pending, (state, action) => {
        state.deleteCategoryLoading = true;
      })
      .addCase(deleteCategoryAsync.fulfilled, (state, action) => {
        state.deleteCategoryLoading = false;
        state.deleteCategorySuccess = true;
        state.categories = state.categories.filter(
          (category) => category.id !== action.meta.arg
        );
        state.pagination.count -= 1;
      })
      .addCase(deleteCategoryAsync.rejected, (state, action) => {
        const { response, config } = action.payload;
        state.deleteCategoryLoading = false;
        state.deleteCategoryError = checkError(response, config, "category");
      });
  },
});

export const { clearCreateCategory, clearUpdateCategory, clearDeleteCategory } =
  categorySlice.actions;
export default categorySlice;
