import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../utils/axios";
import { checkError } from "../../../utils/checkError";

const INITIAL_STATE = {
  fetchGroupsLoading: false,
  fetchGroupsError: null,

  createGroupLoading: false,
  createGroupSuccess: false,
  createGroupError: null,

  deleteGroupLoading: false,
  deleteGroupSuccess: false,
  deleteGroupError: null,

  groups: [],
  pagination: {
    previous: null,
    next: null,
    count: 0,
  },
};

export const fetchGroupsAsync = createAsyncThunk(
  "group/fetchGroupsAsync",
  async ({ all, page, payload }, { rejectWithValue }) => {
    try {
      const { data } = await api.get("/groups/", {
        params: !all
          ? {
              page,
              name__icontains: payload,
            }
          : {
              all: true,
            },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createGroupAsync = createAsyncThunk(
  "group/createGroupAsync",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await api.post("/groups/", payload);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteGroupAsync = createAsyncThunk(
  "group/deleteGroupAsync",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await api.delete(`/groups/${payload}`);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const groupSlice = createSlice({
  name: "group",
  initialState: INITIAL_STATE,
  reducers: {
    clearCreateGroup: (state) => {
      state.createGroupLoading = false;
      state.createGroupSuccess = false;
      state.createGroupError = null;
    },
    clearDeleteGroup: (state) => {
      state.deleteGroupLoading = false;
      state.deleteGroupSuccess = false;
      state.deleteGroupError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGroupsAsync.pending, (state) => {
        state.fetchGroupsLoading = true;
      })
      .addCase(fetchGroupsAsync.fulfilled, (state, action) => {
        const { results, previous, next, count } = action.payload;
        state.fetchGroupsLoading = false;
        state.groups = results;
        state.pagination = {
          previous,
          next,
          count,
        };
      })
      .addCase(fetchGroupsAsync.rejected, (state, action) => {
        state.fetchGroupsLoading = false;
        state.fetchGroupsError = action.payload;
      })

      .addCase(createGroupAsync.pending, (state) => {
        state.createGroupLoading = true;
      })
      .addCase(createGroupAsync.fulfilled, (state, action) => {
        state.createGroupLoading = false;
        state.createGroupSuccess = true;
        state.pagination.count += 1;
      })
      .addCase(createGroupAsync.rejected, (state, action) => {
        const { response } = action.payload;
        state.createGroupSuccess = false;
        state.createGroupError = checkError(response);
      })

      .addCase(deleteGroupAsync.pending, (state) => {
        state.deleteGroupLoading = true;
      })
      .addCase(deleteGroupAsync.fulfilled, (state, action) => {
        state.deleteGroupLoading = false;
        state.deleteGroupSuccess = true;
        state.groups = state.groups.filter(
          (group) => group.id !== action.meta.arg
        );
        state.pagination.count -= 1;
      })
      .addCase(deleteGroupAsync.rejected, (state, action) => {
        state.deleteGroupSuccess = false;
        state.deleteGroupError = action.payload;
      });
  },
});

export const { clearCreateGroup, clearDeleteGroup } = groupSlice.actions;
export default groupSlice;
