import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../utils/axios";

const INITIAL_STATE = {
  fetchProductStocksLoading: false,
  fetchProductStocksError: null,

  filterProductStockSuccess: false,

  productStocks: [],
  productStock: null,

  filterOptions: {
    unit_price: {
      checked: false,
      equality: null,
      value: null,
    },
    total_price: {
      checked: false,
      equality: null,
      value: null,
    },
    total_stock: {
      checked: false,
      equality: null,
      value: null,
    },
  },

  pagination: {
    count: 0,
    previous: null,
    next: null,
  },
};

export const fetchProductStocksAsync = createAsyncThunk(
  "productStock/fetchProductStocksAsync",
  async ({ all, page, key, payload }, { rejectWithValue }) => {
    try {
      const { data } = await api.get("/product-stocks/", {
        params: {
          page,
          [`${key}__icontains`]: payload,
        },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const filterProductStocksAsync = createAsyncThunk(
  "productStock/filterProductStocksAsync",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await api.get("/product-stocks/", {
        params: {
          ...payload,
        },
      });
      return data.results;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const productStockSlice = createSlice({
  name: "productStock",
  initialState: INITIAL_STATE,
  reducers: {
    clearFilterProductStock: (state) => {
      state.filterProductStockSuccess = false;
    },
    clearFilterOptions: (state) => {
      state.filterOptions = INITIAL_STATE.filterOptions;
    },
    setFilterProductStock: (state, action) => {
      const key = Object.keys(action.payload)[0];
      state.filterOptions[key] = action.payload[key];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductStocksAsync.pending, (state) => {
        state.fetchProductStocksLoading = true;
        state.fetchProductStocksError = null;
      })
      .addCase(fetchProductStocksAsync.fulfilled, (state, action) => {
        const { results, previous, next, count } = action.payload;
        state.fetchProductStocksLoading = false;
        state.fetchProductStocksError = null;
        state.productStocks = results;
        state.pagination = {
          previous,
          next,
          count,
        };
      })
      .addCase(fetchProductStocksAsync.rejected, (state, action) => {
        state.fetchProductStocksLoading = false;
        state.fetchProductStocksError = action.payload;
      })

      .addCase(filterProductStocksAsync.pending, (state) => {
        state.fetchProductStocksLoading = true;
      })
      .addCase(filterProductStocksAsync.fulfilled, (state, action) => {
        state.fetchProductStocksLoading = false;
        state.filterProductStockSuccess = true;
        state.productStocks = action.payload;
      })
      .addCase(filterProductStocksAsync.rejected, (state, action) => {
        state.fetchProductStocksLoading = false;
        state.fetchProductStocksError = action.payload;
      });
  },
});

export const {
  clearFilterProductStock,
  clearFilterOptions,
  setFilterProductStock,
} = productStockSlice.actions;
export default productStockSlice;
