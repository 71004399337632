import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../utils/axios";
import { checkError } from "../../../utils/checkError";

const INITIAL_STATE = {
  fetchSubCategoriesLoading: false,
  fetchSubCategoriesError: null,

  createSubCategoryLoading: false,
  createSubCategorySuccess: false,
  createSubCategoryError: null,

  updateSubCategoryLoading: false,
  updateSubCategorySuccess: false,
  updateSubCategoryError: null,

  deleteSubCategoryLoading: false,
  deleteSubCategorySuccess: false,
  deleteSubCategoryError: null,

  subCategories: [],
  pagination: {
    previous: null,
    next: null,
    count: 0,
  },
};

export const fetchSubCategoriesAsync = createAsyncThunk(
  "subCategory/fetchSubCategoriesAsync",
  async ({ page, payload }, { rejectWithValue }) => {
    try {
      const { data } = await api.get("/sub-categories/", {
        params: {
          page: page,
          description__icontains: payload,
          name__icontains: payload,
          category__name__icontains: payload,
        },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createSubCategoryAsync = createAsyncThunk(
  "subCategory/createSubCategoryAsync",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await api.post("/sub-categories/", payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateSubCategoryAsync = createAsyncThunk(
  "subCategory/updateSubCategoryAsync",
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const { data } = await api.put(`/sub-categories/${id}/`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteSubCategoryAsync = createAsyncThunk(
  "subCategory/deleteSubCategoryAsync",
  async (payload, { rejectWithValue }) => {
    try {
      await api.delete(`/sub-categories/${payload}`);
      return payload;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const subCategorySlice = createSlice({
  name: "subCategory",
  initialState: INITIAL_STATE,
  reducers: {
    clearCreateSubCategory: (state) => {
      state.createSubCategoryLoading = false;
      state.createSubCategorySuccess = false;
      state.createSubCategoryError = null;
    },
    clearUpdateSubCategory: (state) => {
      state.updateSubCategoryLoading = false;
      state.updateSubCategorySuccess = false;
      state.updateSubCategoryError = null;
    },
    clearDeleteSubCategory: (state) => {
      state.deleteSubCategoryLoading = false;
      state.deleteSubCategorySuccess = false;
      state.deleteSubCategoryError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubCategoriesAsync.pending, (state) => {
        state.fetchSubCategoriesLoading = true;
        state.fetchSubCategoriesError = null;
      })
      .addCase(fetchSubCategoriesAsync.fulfilled, (state, action) => {
        const { results, previous, next, count } = action.payload;
        state.fetchSubCategoriesLoading = false;
        state.subCategories = results;
        state.pagination = {
          previous,
          next,
          count,
        };
      })
      .addCase(fetchSubCategoriesAsync.rejected, (state, action) => {
        state.fetchSubCategoriesLoading = false;
        state.fetchSubCategoriesError = action.payload;
      })

      .addCase(createSubCategoryAsync.pending, (state) => {
        state.createSubCategoryLoading = true;
        state.createSubCategorySuccess = false;
        state.createSubCategoryError = null;
      })
      .addCase(createSubCategoryAsync.fulfilled, (state, action) => {
        state.createSubCategoryLoading = false;
        state.createSubCategorySuccess = true;
        state.createSubCategoryError = null;
        state.subCategories.unshift(action.payload);
        state.pagination.count += 1;
      })
      .addCase(createSubCategoryAsync.rejected, (state, action) => {
        const { response } = action.payload;
        state.createSubCategoryLoading = false;
        state.createSubCategorySuccess = false;
        state.createSubCategoryError = checkError(response);
      })

      .addCase(updateSubCategoryAsync.pending, (state) => {
        state.updateSubCategoryLoading = true;
        state.updateSubCategorySuccess = false;
        state.updateSubCategoryError = null;
      })
      .addCase(updateSubCategoryAsync.fulfilled, (state, action) => {
        state.updateSubCategoryLoading = false;
        state.updateSubCategorySuccess = true;
        state.updateSubCategoryError = null;
        state.subCategories = state.subCategories.map((subCategory) => {
          if (subCategory.id === action.payload.id) {
            return action.payload;
          }
          return subCategory;
        });
      })
      .addCase(updateSubCategoryAsync.rejected, (state, action) => {
        const { response } = action.payload;
        state.updateSubCategoryLoading = false;
        state.updateSubCategorySuccess = false;
        state.updateSubCategoryError = checkError(response);
      })

      .addCase(deleteSubCategoryAsync.pending, (state) => {
        state.deleteSubCategoryLoading = true;
        state.deleteSubCategorySuccess = false;
        state.deleteSubCategoryError = null;
      })
      .addCase(deleteSubCategoryAsync.fulfilled, (state, action) => {
        state.deleteSubCategoryLoading = false;
        state.deleteSubCategorySuccess = true;
        state.deleteSubCategoryError = null;
        state.subCategories = state.subCategories.filter(
          (subCategory) => subCategory.id !== action.meta.arg
        );
        state.pagination.count -= 1;
      })
      .addCase(deleteSubCategoryAsync.rejected, (state, action) => {
        const { response, config } = action.payload;
        state.deleteSubCategoryLoading = false;
        state.deleteSubCategorySuccess = false;
        state.deleteSubCategoryError = checkError(
          response,
          config,
          "sub-category"
        );
      });
  },
});

export const {
  clearCreateSubCategory,
  clearUpdateSubCategory,
  clearDeleteSubCategory,
} = subCategorySlice.actions;
export default subCategorySlice;
