function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    brands: path(ROOTS_DASHBOARD, "/brands"),
    contactUs: path(ROOTS_DASHBOARD, "/contact-us"),
    requestItems: path(ROOTS_DASHBOARD, "/request-items"),
    inventory: path(ROOTS_DASHBOARD, "/inventory"),
    mainCategories: path(ROOTS_DASHBOARD, "/main-categories"),
    categories: path(ROOTS_DASHBOARD, "/categories"),
    subCategories: path(ROOTS_DASHBOARD, "/sub-categories"),
    orders: path(ROOTS_DASHBOARD, "/orders"),
    products: path(ROOTS_DASHBOARD, "/products"),
    newProduct: path(ROOTS_DASHBOARD, "/products/create"),
    editProduct: path(ROOTS_DASHBOARD, "/products/update"),
    variations: path(ROOTS_DASHBOARD, "/variations"),
    variation_options: path(ROOTS_DASHBOARD, "/variation-options"),
    users: path(ROOTS_DASHBOARD, "/users"),
    newUser: path(ROOTS_DASHBOARD, "/users/create"),
    editUser: path(ROOTS_DASHBOARD, "/users/update"),
    groups: path(ROOTS_DASHBOARD, "/groups"),
    newGroup: path(ROOTS_DASHBOARD, "/groups/create"),
    permissions: path(ROOTS_DASHBOARD, "/permissions"),
    app: path(ROOTS_DASHBOARD, "/app"),
  },
  user: {
    profile: path(ROOTS_DASHBOARD, "/profile"),
    account: path(ROOTS_DASHBOARD, "/account"),
  },
};
