import { useSelector } from "react-redux";
import { Navigate } from "react-router";
import { PATH_DASHBOARD } from "../routes/paths";

export default function AuthGuard({ children }) {
  const { accessToken } = useSelector((state) => state.auth);

  if (accessToken) {
    return <Navigate to={PATH_DASHBOARD.root} />;
  }

  return children;
}
