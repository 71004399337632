import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../utils/axios";
import { checkError } from "../../../utils/checkError";

const INITIAL_STATE = {
  fetchProductVariationOptionValuesLoading: false,
  fetchProductVariationOptionValuesError: null,

  createProductVariationOptionValueLoading: false,
  createProductVariationOptionValueSuccess: false,
  createProductVariationOptionValueError: null,

  updateProductVariationOptionValueLoading: false,
  updateProductVariationOptionValueSuccess: false,
  updateProductVariationOptionValueError: null,

  deleteProductVariationOptionValueLoading: false,
  deleteProductVariationOptionValueSuccess: false,
  deleteProductVariationOptionValueError: null,

  productVariationOptionValues: [],
  pagination: {
    previous: null,
    next: null,
    count: 0,
  },
};

export const fetchProductVariationOptionValuesAsync = createAsyncThunk(
  "productVariationOptionValue/fetchProductVariationOptionValuesAsync",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get("/product-variation-options-values/");
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createProductVariationOptionValueAsync = createAsyncThunk(
  "productVariationOptionValue/createProductVariationOptionValueAsync",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await api.post(
        "/product-variation-options-values/",
        payload
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateProductVariationOptionValueAsync = createAsyncThunk(
  "productVariationOptionValue/updateProductVariationOptionValueAsync",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await api.put(
        `/product-variation-options-values/${payload.id}/`,
        payload
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteProductVariationOptionValueAsync = createAsyncThunk(
  "productVariationOptionValue/deleteProductVariationOptionValueAsync",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await api.delete(
        `/product-variation-options-values/${id}/`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const productVariationOptionValueSlice = createSlice({
  name: "productVariationOptionValue",
  initialState: INITIAL_STATE,
  reducers: {
    clearCreateProductVariationOptionValue: (state) => {
      state.createProductVariationOptionValueLoading = false;
      state.createProductVariationOptionValueSuccess = false;
      state.createProductVariationOptionValueError = null;
    },

    clearUpdateProductVariationOptionValue: (state) => {
      state.updateProductVariationOptionValueLoading = false;
      state.updateProductVariationOptionValueSuccess = false;
      state.updateProductVariationOptionValueError = null;
    },

    clearDeleteProductVariationOptionValue: (state) => {
      state.deleteProductVariationOptionValueLoading = false;
      state.deleteProductVariationOptionValueSuccess = false;
      state.deleteProductVariationOptionValueError = null;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(
        fetchProductVariationOptionValuesAsync.pending,
        (state, action) => {
          state.fetchProductVariationOptionValuesLoading = true;
        }
      )
      .addCase(
        fetchProductVariationOptionValuesAsync.fulfilled,
        (state, action) => {
          const { results, previous, next, count } = action.payload;
          state.fetchProductVariationOptionValuesLoading = false;
          state.productVariationOptionValues = results;
          state.pagination = {
            previous,
            next,
            count,
          };
        }
      )
      .addCase(
        fetchProductVariationOptionValuesAsync.rejected,
        (state, action) => {
          state.fetchProductVariationOptionValuesLoading = false;
          state.fetchProductVariationOptionValuesError = action.payload;
        }
      )

      .addCase(
        createProductVariationOptionValueAsync.pending,
        (state, action) => {
          state.createProductVariationOptionValueLoading = true;
        }
      )
      .addCase(
        createProductVariationOptionValueAsync.fulfilled,
        (state, action) => {
          state.createProductVariationOptionValueLoading = false;
          state.createProductVariationOptionValueSuccess = true;
          state.productVariationOptionValues.unshift(action.payload);
        }
      )
      .addCase(
        createProductVariationOptionValueAsync.rejected,
        (state, action) => {
          const { response } = action.payload;
          state.createProductVariationOptionValueLoading = false;
          state.createProductVariationOptionValueError = checkError(response);
        }
      )

      .addCase(
        updateProductVariationOptionValueAsync.pending,
        (state, action) => {
          state.updateProductVariationOptionValueLoading = true;
        }
      )
      .addCase(
        updateProductVariationOptionValueAsync.fulfilled,
        (state, action) => {
          state.updateProductVariationOptionValueLoading = false;
          state.updateProductVariationOptionValueSuccess = true;
          const index = state.productVariationOptionValues.findIndex(
            (productVariationOptionValue) =>
              productVariationOptionValue.id === action.payload.id
          );
          state.productVariationOptionValues[index] = action.payload;
        }
      )
      .addCase(
        updateProductVariationOptionValueAsync.rejected,
        (state, action) => {
          const { response } = action.payload;
          state.updateProductVariationOptionValueLoading = false;
          state.updateProductVariationOptionValueError = checkError(response);
        }
      )

      .addCase(
        deleteProductVariationOptionValueAsync.pending,
        (state, action) => {
          state.deleteProductVariationOptionValueLoading = true;
        }
      )
      .addCase(
        deleteProductVariationOptionValueAsync.fulfilled,
        (state, action) => {
          state.deleteProductVariationOptionValueLoading = false;
          state.deleteProductVariationOptionValueSuccess = true;
          const index = state.productVariationOptionValues.findIndex(
            (productVariationOptionValue) =>
              productVariationOptionValue.id === action.meta.arg
          );
          state.productVariationOptionValues.splice(index, 1);
        }
      )
      .addCase(
        deleteProductVariationOptionValueAsync.rejected,
        (state, action) => {
          state.deleteProductVariationOptionValueLoading = false;
          state.deleteProductVariationOptionValueError = action.payload;
        }
      );
  },
});

export const {
  clearCreateProductVariationOptionValue,
  clearUpdateProductVariationOptionValue,
  clearDeleteProductVariationOptionValue,
} = productVariationOptionValueSlice.actions;

export default productVariationOptionValueSlice;
