import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";

import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import {
  clearLogin,
  loginAsync,
} from "../../../store/features/auth/auth.slice";
import { useSnackbar } from "notistack5";

export default function LoginForm() {
  /**
   * states
   */
  const [showPassword, setShowPassword] = useState(false);

  /**
   * hooks
   */
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  /**
   * selectors
   */
  const { loginLoading, loginError } = useSelector((state) => state.auth);

  /**
   * functions
   */
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  /**
   * yup and formik
   */
  const LoginSchema = Yup.object().shape({
    phoneEmail: Yup.string().required("Email or Phone number is required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      phoneEmail: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const loginPayload = {
          phoneEmail: values.phoneEmail,
          password: values.password,
        };
        dispatch(loginAsync(loginPayload));
      } catch (error) {
        resetForm();
      }
    },
  });

  /**
   * effects
   */
  useEffect(() => {
    if (loginError) {
      enqueueSnackbar(loginError, { variant: "error" });
      dispatch(clearLogin());
    }
  }, [loginError, dispatch, enqueueSnackbar]);

  /**
   * variables
   */
  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3} mb={3}>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email or Phone number"
              {...getFieldProps("phoneEmail")}
              error={Boolean(touched.phoneEmail && errors.phoneEmail)}
              helperText={touched.phoneEmail && errors.phoneEmail}
            />

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? "text" : "password"}
              label="Password"
              {...getFieldProps("password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={loginLoading}
            disabled={loginLoading}
          >
            Login
          </LoadingButton>
        </Form>
      </FormikProvider>
    </>
  );
}
