import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../utils/axios";
import { checkError } from "../../../utils/checkError";

const INITIAL_STATE = {
  fetchVariationsLoading: false,
  fetchVariationsError: null,

  createVariationLoading: false,
  createVariationSuccess: false,
  createVariationError: null,

  updateVariationLoading: false,
  updateVariationSuccess: false,
  updateVariationError: null,

  deleteVariationLoading: false,
  deleteVariationSuccess: false,
  deleteVariationError: null,

  variations: [],
  pagination: {
    previous: null,
    next: null,
    count: 0,
  },
};

export const fetchVariationsAsync = createAsyncThunk(
  "variation/fetchVariationsAsync",
  async ({ all, page }, { rejectWithValue }) => {
    try {
      const { data } = await api.get("/variations/", {
        params: !all
          ? {
              page,
            }
          : {
              all: true,
            },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createVariationAsync = createAsyncThunk(
  "variation/createVariationAsync",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await api.post("/variations/", payload);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateVariationAsync = createAsyncThunk(
  "variation/updateVariationAsync",
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const { data } = await api.put(`/variations/${id}/`, payload);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteVariationAsync = createAsyncThunk(
  "variation/deleteVariationAsync",
  async (payload, { rejectWithValue }) => {
    try {
      await api.delete(`/variations/${payload}`);
      return payload;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const variationSlice = createSlice({
  name: "variation",
  initialState: INITIAL_STATE,
  reducers: {
    clearCreateVariation: (state) => {
      state.createVariationLoading = false;
      state.createVariationSuccess = false;
      state.createVariationError = null;
    },
    clearUpdateVariation: (state) => {
      state.updateVariationLoading = false;
      state.updateVariationSuccess = false;
      state.updateVariationError = null;
    },
    clearDeleteVariation: (state) => {
      state.deleteVariationLoading = false;
      state.deleteVariationSuccess = false;
      state.deleteVariationError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVariationsAsync.pending, (state) => {
        state.fetchVariationsLoading = true;
      })
      .addCase(fetchVariationsAsync.fulfilled, (state, action) => {
        const { results, previous, next, count } = action.payload;
        state.fetchVariationsLoading = false;
        state.variations = action.meta.arg.all ? action.payload : results;
        state.pagination = {
          previous,
          next,
          count,
        };
      })
      .addCase(fetchVariationsAsync.rejected, (state, action) => {
        state.fetchVariationsLoading = false;
        state.fetchVariationsError = action.payload;
      })

      .addCase(createVariationAsync.pending, (state) => {
        state.createVariationLoading = true;
      })
      .addCase(createVariationAsync.fulfilled, (state, action) => {
        state.createVariationLoading = false;
        state.createVariationSuccess = true;
        state.variations.unshift(action.payload);
        state.pagination.count += 1;
      })
      .addCase(createVariationAsync.rejected, (state, action) => {
        const { response } = action.payload;
        state.createVariationLoading = false;
        state.createVariationError = checkError(response);
      })

      .addCase(updateVariationAsync.pending, (state) => {
        state.updateVariationLoading = true;
      })
      .addCase(updateVariationAsync.fulfilled, (state, action) => {
        state.updateVariationLoading = false;
        state.updateVariationSuccess = true;
        state.variations = state.variations.map((variation) =>
          variation.id === action.payload.id ? action.payload : variation
        );
      })
      .addCase(updateVariationAsync.rejected, (state, action) => {
        const { response } = action.payload;
        state.updateVariationLoading = false;
        state.updateVariationError = checkError(response);
      })

      .addCase(deleteVariationAsync.pending, (state) => {
        state.deleteVariationLoading = true;
      })
      .addCase(deleteVariationAsync.fulfilled, (state, action) => {
        state.deleteVariationLoading = false;
        state.deleteVariationSuccess = true;
        state.variations = state.variations.filter(
          (variation) => variation.id !== action.meta.arg
        );
        state.pagination.count -= 1;
      })
      .addCase(deleteVariationAsync.rejected, (state, action) => {
        const { response, config } = action.payload;
        state.deleteVariationLoading = false;
        state.deleteVariationError = checkError(response, config, "variation");
      });
  },
});

export const {
  clearCreateVariation,
  clearUpdateVariation,
  clearDeleteVariation,
} = variationSlice.actions;
export default variationSlice;
