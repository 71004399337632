import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../utils/axios";
import { checkError } from "../../../utils/checkError";

const INITIAL_STATE = {
  fetchVariationOptionsLoading: false,
  fetchVariationOptionsSuccess: false,

  createVariationOptionLoading: false,
  createVariationOptionSuccess: false,
  createVariationOptionError: null,

  updateVariationOptionLoading: false,
  updateVariationOptionSuccess: false,
  updateVariationOptionError: null,

  deleteVariationOptionLoading: false,
  deleteVariationOptionSuccess: false,
  deleteVariationOptionError: null,

  variationOptions: [],
  pagination: {
    previous: null,
    next: null,
    count: 0,
  },
};

export const fetchVariationOptionsAsync = createAsyncThunk(
  "variationOption/fetchVariationOptionsAsync",
  async ({ page, payload }, { rejectWithValue }) => {
    try {
      const { data } = await api.get("/variation-options/", {
        params: {
          page: page,
          value__icontains: payload,
        },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createVariationOptionAsync = createAsyncThunk(
  "variationOption/createVariationOptionAsync",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await api.post("/variation-options/", payload);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateVariationOptionAsync = createAsyncThunk(
  "variationOption/updateVariationOptionAsync",
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const { data } = await api.put(`/variation-options/${id}/`, payload);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteVariationOptionAsync = createAsyncThunk(
  "variationOption/deleteVariationOptionAsync",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await api.delete(`/variation-options/${id}/`);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const variationOptionSlice = createSlice({
  name: "variationOption",
  initialState: INITIAL_STATE,
  reducers: {
    clearCreateVariationOption: (state) => {
      state.createVariationOptionLoading = false;
      state.createVariationOptionSuccess = false;
      state.createVariationOptionError = null;
    },
    clearUpdateVariationOption: (state) => {
      state.updateVariationOptionLoading = false;
      state.updateVariationOptionSuccess = false;
      state.updateVariationOptionError = null;
    },
    clearDeleteVariationOption: (state) => {
      state.deleteVariationOptionLoading = false;
      state.deleteVariationOptionSuccess = false;
      state.deleteVariationOptionError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVariationOptionsAsync.pending, (state) => {
        state.fetchVariationOptionsLoading = true;
        state.fetchVariationOptionsSuccess = false;
      })
      .addCase(fetchVariationOptionsAsync.fulfilled, (state, action) => {
        const { results, previous, next, count } = action.payload;
        state.fetchVariationOptionsLoading = false;
        state.fetchVariationOptionsSuccess = true;
        state.variationOptions = results;
        state.pagination = {
          previous,
          next,
          count,
        };
      })
      .addCase(fetchVariationOptionsAsync.rejected, (state, action) => {
        state.fetchVariationOptionsLoading = false;
        state.fetchVariationOptionsSuccess = false;
      })

      .addCase(createVariationOptionAsync.pending, (state) => {
        state.createVariationOptionLoading = true;
        state.createVariationOptionSuccess = false;
        state.createVariationOptionError = null;
      })
      .addCase(createVariationOptionAsync.fulfilled, (state, action) => {
        state.createVariationOptionLoading = false;
        state.createVariationOptionSuccess = true;
        state.variationOptions.unshift(action.payload);
        state.pagination.count += 1;
      })
      .addCase(createVariationOptionAsync.rejected, (state, action) => {
        const { response } = action.payload;
        state.createVariationOptionLoading = false;
        state.createVariationOptionSuccess = false;
        state.createVariationOptionError = checkError(response);
      })

      .addCase(updateVariationOptionAsync.pending, (state) => {
        state.updateVariationOptionLoading = true;
        state.updateVariationOptionSuccess = false;
        state.updateVariationOptionError = null;
      })
      .addCase(updateVariationOptionAsync.fulfilled, (state, action) => {
        state.updateVariationOptionLoading = false;
        state.updateVariationOptionSuccess = true;
        state.variationOptions = state.variationOptions.map(
          (variationOption) => {
            if (variationOption.id === action.payload.id) {
              return action.payload;
            }
            return variationOption;
          }
        );
      })
      .addCase(updateVariationOptionAsync.rejected, (state, action) => {
        const { response } = action.payload;
        state.updateVariationOptionLoading = false;
        state.updateVariationOptionSuccess = false;
        state.updateVariationOptionError = checkError(response);
      })

      .addCase(deleteVariationOptionAsync.pending, (state) => {
        state.deleteVariationOptionLoading = true;
        state.deleteVariationOptionSuccess = false;
        state.deleteVariationOptionError = null;
      })
      .addCase(deleteVariationOptionAsync.fulfilled, (state, action) => {
        state.deleteVariationOptionLoading = false;
        state.deleteVariationOptionSuccess = true;
        state.variationOptions = state.variationOptions.filter(
          (variationOption) => variationOption.id !== action.meta.arg
        );
        state.pagination.count -= 1;
      })
      .addCase(deleteVariationOptionAsync.rejected, (state, action) => {
        const { response, config } = action.payload;
        state.deleteVariationOptionLoading = false;
        state.deleteVariationOptionSuccess = false;
        state.deleteVariationOptionError = checkError(
          response,
          config,
          "variation option"
        );
      });
  },
});

export const {
  clearCreateVariationOption,
  clearUpdateVariationOption,
  clearDeleteVariationOption,
} = variationOptionSlice.actions;
export default variationOptionSlice;
