import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router";
import Login from "../pages/auth/Login";
import { useSnackbar } from "notistack5";
import { clearLogin } from "../store/features/auth/auth.slice";

export default function DashboardGuard({ children }) {
  const [requestedLocation, setRequestedLocation] = useState(null);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();

  const { accessToken } = useSelector((state) => state.auth);
  const { loginSuccess } = useSelector((state) => state.auth);

  useEffect(() => {
    if (loginSuccess) {
      enqueueSnackbar("Login success", { variant: "success" });
      dispatch(clearLogin());
    }
  }, [loginSuccess, enqueueSnackbar, dispatch]);

  if (!accessToken) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return children;
}
