import axios from "axios";
import { logOut } from "../store/features/auth/auth.slice";

let store;
export const injectStore = (_store) => {
  store = _store;
};

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    const {
      auth: { accessToken },
    } = store.getState();
    config.headers["Authorization"] = `JWT ${accessToken}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const {
      response: { status },
    } = error;

    if (
      status === 401 &&
      error.message === "Request failed with status code 401"
    ) {
      try {
        store.dispatch(logOut());
      } catch (error) {
        store.dispatch(logOut());
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);

export default api;
