import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "./reducer";
import { thunk } from "redux-thunk";
import logger from "redux-logger";
import { persistStore } from "redux-persist";

export const store = configureStore({
  reducer: rootReducer,
  middleware:
    process.env.NODE_ENV === "development"
      ? (getDefaultMiddleware) =>
          getDefaultMiddleware({
            serializableCheck: {
              ignoreActions: ["auth/loginAsync"],
            },
          }).concat(thunk, logger)
      : (getDefaultMiddleware) =>
          getDefaultMiddleware({
            serializableCheck: {
              ignoreActions: ["auth/loginAsync"],
            },
          }).concat(thunk),
});

export const persistor = persistStore(store);
