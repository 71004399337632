import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router";
import { fetchUserAsync } from "../store/features/user/user.slice";
import LoadingScreen from "../components/LoadingScreen";

export default function ProtectedGuard({ groupName, children }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(fetchUserAsync());
  }, [dispatch]);

  if (user === null) {
    return (
      <LoadingScreen
        sx={{
          top: 0,
          left: 0,
          width: 1,
          zIndex: 9999,
          position: "fixed",
        }}
      />
    );
  }

  if (user !== null) {
    if (user.is_superuser) {
      return children;
    }

    if (user.groups.some((x) => x.name.includes(groupName))) {
      return children;
    }
  }

  return <Navigate to={"/404"} />;
}
