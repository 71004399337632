import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";

import DashboardLayout from "../layouts/dashboard";

import LoadingScreen from "../components/LoadingScreen";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
import AuthGuard from "../guards/AuthGuard";
import DashboardGuard from "../guards/DashboardGuard";
import ProtectedGuard from "../guards/ProtectedGuard";

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes("/dashboard");

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed",
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <AuthGuard>
              <Login />
            </AuthGuard>
          ),
        },
      ],
    },

    {
      path: "dashboard",
      element: (
        <DashboardGuard>
          <DashboardLayout />
        </DashboardGuard>
      ),
      children: [
        {
          path: "/dashboard",
          element: <Navigate to="/dashboard/app" replace />,
        },
        { path: "app", element: <DashboardApp /> },
        {
          path: "brands",
          element: (
            <ProtectedGuard groupName={"brand_admin"}>
              <DashboardBrand />
            </ProtectedGuard>
          ),
        },
        {
          path: "inventory",
          element: (
            <ProtectedGuard groupName={"inventory_admin"}>
              <DashboardInventory />
            </ProtectedGuard>
          ),
        },
        {
          path: "main-categories",
          element: (
            <ProtectedGuard groupName={"main_category_admin"}>
              <DashboardMainCategory />
            </ProtectedGuard>
          ),
        },
        {
          path: "categories",
          element: (
            <ProtectedGuard groupName={"category_admin"}>
              <DashboardCategory />
            </ProtectedGuard>
          ),
        },
        {
          path: "sub-categories",
          element: (
            <ProtectedGuard groupName={"sub_category_admin"}>
              <DashboardSubCategory />
            </ProtectedGuard>
          ),
        },
        {
          path: "products",
          element: (
            <ProtectedGuard groupName={"product_admin"}>
              <DashboardProduct />
            </ProtectedGuard>
          ),
        },
        {
          path: "products/create",
          element: (
            <ProtectedGuard groupName={"product_admin"}>
              <CreateProduct />
            </ProtectedGuard>
          ),
        },
        {
          path: "products/update",
          element: (
            <ProtectedGuard groupName={"product_admin"}>
              <UpdateProduct />
            </ProtectedGuard>
          ),
        },
        {
          path: "orders",
          element: (
            <ProtectedGuard groupName={"order_admin"}>
              <DashboardOrder />
            </ProtectedGuard>
          ),
        },
        {
          path: "variations",
          element: (
            <ProtectedGuard groupName={"variation_admin"}>
              <DashboardVariation />
            </ProtectedGuard>
          ),
        },
        {
          path: "variation-options",
          element: (
            <ProtectedGuard groupName={"variation_admin"}>
              <DashboardVariationOptions />
            </ProtectedGuard>
          ),
        },
        {
          path: "users",
          element: (
            <ProtectedGuard groupName={"user_admin"}>
              <DashboardUser />
            </ProtectedGuard>
          ),
        },
        {
          path: "users/create",
          element: (
            <ProtectedGuard groupName={"user_admin"}>
              <CreateUser />
            </ProtectedGuard>
          ),
        },
        {
          path: "users/update",
          element: (
            <ProtectedGuard groupName={"user_admin"}>
              <UpdateUser />
            </ProtectedGuard>
          ),
        },
        {
          path: "groups",
          element: (
            <ProtectedGuard groupName={"user_admin"}>
              <DashboardGroup />
            </ProtectedGuard>
          ),
        },
        {
          path: "groups/create",
          element: (
            <ProtectedGuard groupName={"user_admin"}>
              <CreateGroup />
            </ProtectedGuard>
          ),
        },
        {
          path: "permissions",
          element: (
            <ProtectedGuard groupName={"user_admin"}>
              <DashboardPermission />
            </ProtectedGuard>
          ),
        },
        { path: "contact-us", element: <DashboardContactUs /> },
        { path: "request-items", element: <DashboardRequestItem /> },
      ],
    },

    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },

    {
      path: "/",
      element: <Navigate to="/dashboard/app" replace />,
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// Authentication
const Login = Loadable(lazy(() => import("../pages/auth/Login")));

// Dashboard
const DashboardApp = Loadable(
  lazy(() => import("../pages/dashboard/DashboardApp"))
);
const DashboardBrand = Loadable(
  lazy(() => import("../pages/dashboard/brands/DashboardBrand"))
);
const DashboardOrder = Loadable(
  lazy(() => import("../pages/dashboard/orders/DashboardOrder"))
);
const DashboardInventory = Loadable(
  lazy(() => import("../pages/dashboard/inventory/DashboardInventory"))
);
const DashboardMainCategory = Loadable(
  lazy(() => import("../pages/dashboard/main-categories/DashboardMainCategory"))
);
const DashboardCategory = Loadable(
  lazy(() => import("../pages/dashboard/categories/DashboardCategory"))
);
const DashboardSubCategory = Loadable(
  lazy(() => import("../pages/dashboard/sub-categories/DashboardSubCategory"))
);
const DashboardProduct = Loadable(
  lazy(() => import("../pages/dashboard/products/DashboardProduct"))
);
const CreateProduct = Loadable(
  lazy(() => import("../pages/dashboard/products/CreateProduct"))
);
const UpdateProduct = Loadable(
  lazy(() => import("../pages/dashboard/products/UpdateProduct"))
);
const DashboardVariation = Loadable(
  lazy(() => import("../pages/dashboard/variations/DashboardVariation"))
);
const DashboardVariationOptions = Loadable(
  lazy(() =>
    import("../pages/dashboard/variation-options/DashboardVariationOption")
  )
);
const DashboardUser = Loadable(
  lazy(() => import("../pages/dashboard/users/DashboardUser"))
);
const CreateUser = Loadable(
  lazy(() => import("../pages/dashboard/users/CreateUser"))
);
const UpdateUser = Loadable(
  lazy(() => import("../pages/dashboard/users/UpdateUser"))
);

const DashboardGroup = Loadable(
  lazy(() => import("../pages/dashboard/groups/DashboardGroup"))
);

const CreateGroup = Loadable(
  lazy(() => import("../pages/dashboard/groups/CreateGroup"))
);

const DashboardPermission = Loadable(
  lazy(() => import("../pages/dashboard/permissions/DashboardPermission"))
);

const DashboardContactUs = Loadable(
  lazy(() => import("../pages/dashboard/contact-us/DashboardContactUs"))
);

const DashboardRequestItem = Loadable(
  lazy(() => import("../pages/dashboard/request-items/DashboardRequestItem"))
);

// Error
const NotFound = Loadable(lazy(() => import("../pages/error/NotFound")));
