import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../utils/axios";
import { checkError } from "../../../utils/checkError";

const INITIAL_STATE = {
  fetchProductVariationOptionsLoading: false,
  fetchProductVariationOptionsError: null,

  fetchProductVariationOptionsByProductLoading: false,
  fetchProductVariationOptionsByProductError: null,

  createProductVariationOptionLoading: false,
  createProductVariationOptionSuccess: false,
  createProductVariationOptionError: null,

  updateProductVariationOptionLoading: false,
  updateProductVariationOptionSuccess: false,
  updateProductVariationOptionError: null,

  deleteProductVariationOptionLoading: false,
  deleteProductVariationOptionSuccess: false,
  deleteProductVariationOptionError: null,

  productVariationOptions: [],
  pagination: {
    previous: null,
    next: null,
    count: 0,
  },
};

export const fetchProductVariationOptionsAsync = createAsyncThunk(
  "productVariation/fetchProductVariationOptionsAsync",
  async ({ all, payload }, { rejectWithValue }) => {
    try {
      const { data } = await api.get("/product-variation-options/", {
        params: !all
          ? {
              product: payload,
            }
          : {
              product: payload,
              all: true,
            },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createProductVariationOptionAsync = createAsyncThunk(
  "productVariation/createProductVariationOptionAsync",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await api.post("/product-variation-options/", payload);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateProductVariationOptionAsync = createAsyncThunk(
  "productVariation/updateProductVariationOptionAsync",
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const { data } = await api.put(
        `/product-variation-options/${id}/`,
        payload
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteProductVariationOptionAsync = createAsyncThunk(
  "productVariation/deleteProductVariationOptionAsync",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await api.delete(`/product-variation-options/${id}/`);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const productVariationOptionSlice = createSlice({
  name: "productVariationOption",
  initialState: INITIAL_STATE,
  reducers: {
    clearCreateProductVariationOption: (state) => {
      state.createProductVariationOptionLoading = false;
      state.createProductVariationOptionSuccess = false;
      state.createProductVariationOptionError = null;
    },
    clearUpdateProductVariationOption: (state) => {
      state.updateProductVariationOptionLoading = false;
      state.updateProductVariationOptionSuccess = false;
      state.updateProductVariationOptionError = null;
    },
    clearDeleteProductVariationOption: (state) => {
      state.deleteProductVariationOptionLoading = false;
      state.deleteProductVariationOptionSuccess = false;
      state.deleteProductVariationOptionError = null;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchProductVariationOptionsAsync.pending, (state) => {
        state.fetchProductVariationOptionsLoading = true;
      })
      .addCase(fetchProductVariationOptionsAsync.fulfilled, (state, action) => {
        const { results, previous, next, count } = action.payload;
        state.fetchProductVariationOptionsLoading = false;
        state.productVariationOptions = action.meta.arg.all
          ? action.payload
          : results;
        state.pagination = {
          previous,
          next,
          count,
        };
      })
      .addCase(
        fetchProductVariationOptionsAsync.rejected,
        (state, { payload }) => {
          state.fetchProductVariationOptionsLoading = false;
          state.fetchProductVariationOptionsError = payload;
        }
      )

      .addCase(createProductVariationOptionAsync.pending, (state) => {
        state.createProductVariationOptionLoading = true;
      })
      .addCase(
        createProductVariationOptionAsync.fulfilled,
        (state, { payload }) => {
          state.createProductVariationOptionLoading = false;
          state.createProductVariationOptionSuccess = true;
          state.productVariationOptions.unshift(payload);
          state.pagination.count += 1;
        }
      )
      .addCase(createProductVariationOptionAsync.rejected, (state, action) => {
        const { response } = action.payload;
        state.createProductVariationOptionLoading = false;
        state.createProductVariationOptionError = checkError(response);
      })

      .addCase(updateProductVariationOptionAsync.pending, (state) => {
        state.updateProductVariationOptionLoading = true;
      })
      .addCase(
        updateProductVariationOptionAsync.fulfilled,
        (state, { payload }) => {
          state.updateProductVariationOptionLoading = false;
          state.updateProductVariationOptionSuccess = true;
          state.productVariationOptions = state.productVariationOptions.map(
            (productVariation) =>
              productVariation.id === payload.id ? payload : productVariation
          );
        }
      )
      .addCase(
        updateProductVariationOptionAsync.rejected,
        (state, { payload }) => {
          const { response } = payload;
          state.updateProductVariationOptionLoading = false;
          state.updateProductVariationOptionError = checkError(response);
        }
      )

      .addCase(deleteProductVariationOptionAsync.pending, (state) => {
        state.deleteProductVariationOptionLoading = true;
      })
      .addCase(deleteProductVariationOptionAsync.fulfilled, (state, action) => {
        state.deleteProductVariationOptionLoading = false;
        state.deleteProductVariationOptionSuccess = true;
        state.productVariationOptions = state.productVariationOptions.filter(
          (productVariation) => productVariation.id !== action.meta.arg
        );
        state.pagination.count -= 1;
      })
      .addCase(
        deleteProductVariationOptionAsync.rejected,
        (state, { payload }) => {
          state.deleteProductVariationOptionLoading = false;
          state.deleteProductVariationOptionError = payload;
        }
      );
  },
});

export const {
  clearCreateProductVariationOption,
  clearUpdateProductVariationOption,
  clearDeleteProductVariationOption,
} = productVariationOptionSlice.actions;
export default productVariationOptionSlice;
