import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../utils/axios";
import { checkError } from "../../../utils/checkError";

const INITIAL_STATE = {
  loginLoading: false,
  loginSuccess: false,
  loginError: null,

  accessToken: null,
  refreshToken: null,
};

export const loginAsync = createAsyncThunk(
  "auth/login",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.post("/auth/jwt/signin/", payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: INITIAL_STATE,
  reducers: {
    logOut: (state) => {
      state.accessToken = null;
      state.refreshToken = null;
    },
    clearLogin: (state) => {
      state.loginLoading = false;
      state.loginSuccess = false;
      state.loginError = null;
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state, action) => {
        state.loginLoading = true;
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        const { access, refresh, user } = action.payload.data;
        state.loginLoading = false;
        state.loginSuccess = true;
        if (user.role === "admin") {
          if (user.groups.length === 0 && !user.is_superuser) {
            state.loginError =
              "You have not been assigned to any group, Kindly, contact your admin.";
          } else {
            state.accessToken = access;
            state.refreshToken = refresh;
          }
        } else {
          state.loginError = "You are not authorized to access this page";
        }
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.loginLoading = false;
        state.loginSuccess = false;
        if (action.payload.detail) {
          state.loginError = action.payload.detail;
        } else {
          const { response, config } = action.payload;
          state.loginError = checkError(response, config);
        }
      });
  },
});

export const { logOut, clearLogin, setAccessToken } = authSlice.actions;
export default authSlice;
