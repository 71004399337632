import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../utils/axios";
import { checkError } from "../../../utils/checkError";

const INITIAL_STATE = {
  fetchOrdersLoading: false,
  fetchOrdersError: null,

  changeOrderStatusLoading: false,
  changeOrderStatusSuccess: false,
  changeOrderStatusError: null,

  expireOrdersLoading: false,
  expireOrdersSuccess: false,
  expireOrdersError: null,

  checkChapaOrderTransactionLoading: false,
  checkChapaOrderTransactionSuccess: false,
  checkChapaOrderTransactionError: null,

  updateOrderLoading: false,
  updateOrderSuccess: false,
  updateOrderError: null,

  orders: [],
  order: null,
  pagination: {
    count: 0,
    previous: null,
    next: null,
  },
  chapaOrderTransaction: null,
};

export const fetchOrdersAsync = createAsyncThunk(
  "order/fetchOrdersAsync",
  async ({ all, page, payload }, { rejectWithValue }) => {
    try {
      const { data } = await api.get("/orders/", {
        params: !all
          ? {
              page,
            }
          : {
              all: true,
            },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const filterOrdersByStatusAsync = createAsyncThunk(
  "order/filterOrdersByStatusAsync",
  async (
    { trackNumber, status, deliveryType, paymentMethod },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.get(`/orders/`, {
        params: {
          status,
          deliveryType,
          paymentMethod,
          trackNumber,
        },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const changeOrderStatusAsync = createAsyncThunk(
  "order/changeOrderStatusAsync",
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const { data } = await api.patch(`/orders/${id}/`, payload);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const expireOrdersAsync = createAsyncThunk(
  "order/expireOrdersAsync",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get("/update-order-status/");
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const checkChapaOrderTransactionAsync = createAsyncThunk(
  "order/checkChapaOrderTransactionAsync",
  async ({ tx_ref }, { rejectWithValue }) => {
    try {
      const { data } = await api.get("/orders/chapa-verify", {
        params: {
          tx_ref,
        },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateOrderAsync = createAsyncThunk(
  "order/updateOrderAsync",
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const { data } = await api.patch(`/orders/${id}/`, payload);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const orderSlice = createSlice({
  name: "order",
  initialState: INITIAL_STATE,
  reducers: {
    clearChangeOrderStatus: (state) => {
      state.changeOrderStatusLoading = false;
      state.changeOrderStatusSuccess = false;
      state.changeOrderStatusError = null;
    },

    clearExpireOrders: (state) => {
      state.expireOrdersLoading = false;
      state.expireOrdersSuccess = false;
      state.expireOrdersError = null;
    },

    clearCheckChapaOrderTransaction: (state) => {
      state.checkChapaOrderTransactionLoading = false;
      state.checkChapaOrderTransactionSuccess = false;
      state.checkChapaOrderTransactionError = null;
      state.chapaOrderTransaction = null;
    },

    clearUpdateOrder: (state) => {
      state.updateOrderLoading = false;
      state.updateOrderSuccess = false;
      state.updateOrderError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrdersAsync.pending, (state) => {
        state.fetchOrdersLoading = true;
        state.fetchOrdersError = null;
      })
      .addCase(fetchOrdersAsync.fulfilled, (state, action) => {
        const { results, count, previous, next } = action.payload;
        state.fetchOrdersLoading = false;
        state.orders = results;
        state.pagination = {
          count,
          previous,
          next,
        };
      })
      .addCase(fetchOrdersAsync.rejected, (state, action) => {
        state.fetchOrdersLoading = false;
        state.fetchOrdersError = checkError(action.payload);
      })

      .addCase(filterOrdersByStatusAsync.pending, (state) => {})
      .addCase(filterOrdersByStatusAsync.fulfilled, (state, action) => {
        const { results, count, previous, next } = action.payload;
        state.fetchOrdersLoading = false;
        state.orders = results;
        state.pagination = {
          count,
          previous,
          next,
        };
      })
      .addCase(filterOrdersByStatusAsync.rejected, (state, action) => {
        state.fetchOrdersLoading = false;
        state.fetchOrdersError = checkError(action.payload);
      })

      .addCase(changeOrderStatusAsync.pending, (state) => {
        state.changeOrderStatusLoading = true;
        state.changeOrderStatusSuccess = false;
        state.changeOrderStatusError = null;
      })
      .addCase(changeOrderStatusAsync.fulfilled, (state, action) => {
        state.changeOrderStatusLoading = false;
        state.changeOrderStatusSuccess = true;
        state.orders = state.orders.map((order) => {
          if (order.id === action.payload.id) {
            return action.payload;
          }
          return order;
        });
      })
      .addCase(changeOrderStatusAsync.rejected, (state, action) => {
        state.changeOrderStatusLoading = false;
        state.changeOrderStatusSuccess = false;
        state.changeOrderStatusError = checkError(action.payload);
      })

      .addCase(expireOrdersAsync.pending, (state) => {
        state.expireOrdersLoading = true;
        state.expireOrdersSuccess = false;
        state.expireOrdersError = null;
      })
      .addCase(expireOrdersAsync.fulfilled, (state, action) => {
        state.expireOrdersLoading = false;
        state.expireOrdersSuccess = true;
      })
      .addCase(expireOrdersAsync.rejected, (state, action) => {
        state.expireOrdersLoading = false;
        state.expireOrdersSuccess = false;
        state.expireOrdersError = checkError(action.payload);
      })

      .addCase(checkChapaOrderTransactionAsync.pending, (state) => {
        state.checkChapaOrderTransactionLoading = true;
        state.checkChapaOrderTransactionSuccess = false;
        state.checkChapaOrderTransactionError = null;
      })
      .addCase(checkChapaOrderTransactionAsync.fulfilled, (state, action) => {
        state.checkChapaOrderTransactionLoading = false;
        state.checkChapaOrderTransactionSuccess = true;
        state.chapaOrderTransaction = action.payload;
      })
      .addCase(checkChapaOrderTransactionAsync.rejected, (state, action) => {
        state.checkChapaOrderTransactionLoading = false;
        state.checkChapaOrderTransactionSuccess = false;
        state.checkChapaOrderTransactionError = checkError(action.payload);
      })

      .addCase(updateOrderAsync.pending, (state) => {
        state.updateOrderLoading = true;
        state.updateOrderSuccess = false;
        state.updateOrderError = null;
      })
      .addCase(updateOrderAsync.fulfilled, (state, action) => {
        state.updateOrderLoading = false;
        state.updateOrderSuccess = true;
        state.orders = state.orders.map((order) => {
          if (order.id === action.payload.id) {
            return action.payload;
          }
          return order;
        });
      })
      .addCase(updateOrderAsync.rejected, (state, action) => {
        state.updateOrderLoading = false;
        state.updateOrderSuccess = false;
        state.updateOrderError = checkError(action.payload);
      });
  },
});

export const {
  clearChangeOrderStatus,
  clearExpireOrders,
  clearCheckChapaOrderTransaction,
  clearUpdateOrder,
} = orderSlice.actions;
export default orderSlice;
