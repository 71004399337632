import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../utils/axios";

const INITIAL_STATE = {
  fetchContactUsLoading: false,
  fetchContactUsSuccess: false,
  fetchContactUsError: null,

  deleteContactUsLoading: false,
  deleteContactUsSuccess: false,
  deleteContactUsError: null,

  contactUs: [],
  pagination: {
    previous: null,
    next: null,
    count: 0,
  },
};

export const fetchContactUsAsync = createAsyncThunk(
  "contactUs/fetchContactUsAsync",
  async ({ page, payload }, { rejectWithValue }) => {
    try {
      const { data } = await api.get("/contacts/", {
        params: {
          page,
          name__icontains: payload,
          email__icontains: payload,
        },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteContactUsAsync = createAsyncThunk(
  "contactUs/deleteContactUsAsync",
  async (payload, { rejectWithValue }) => {
    try {
      await api.delete(`/contacts/${payload}`);
      return payload;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const contactusSlice = createSlice({
  name: "contactUs",
  initialState: INITIAL_STATE,
  reducers: {
    clearDeleteContactUs: (state) => {
      state.deleteContactUsLoading = false;
      state.deleteContactUsSuccess = false;
      state.deleteContactUsError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContactUsAsync.pending, (state) => {
        state.fetchContactUsLoading = true;
        state.fetchContactUsSuccess = false;
        state.fetchContactUsError = null;
      })
      .addCase(fetchContactUsAsync.fulfilled, (state, action) => {
        const { results, previous, next, count } = action.payload;
        state.fetchContactUsLoading = false;
        state.fetchContactUsSuccess = true;
        state.contactUs = results;
        state.pagination = {
          previous,
          next,
          count,
        };
      })
      .addCase(fetchContactUsAsync.rejected, (state, action) => {
        state.fetchContactUsLoading = false;
        state.fetchContactUsError = action.payload;
      })

      .addCase(deleteContactUsAsync.pending, (state) => {
        state.deleteContactUsLoading = true;
        state.deleteContactUsSuccess = false;
        state.deleteContactUsError = null;
      })
      .addCase(deleteContactUsAsync.fulfilled, (state, action) => {
        state.deleteContactUsLoading = false;
        state.deleteContactUsSuccess = true;
        state.contactUs = state.contactUs.filter(
          (contactUs) => contactUs.id !== action.meta.arg
        );
      })
      .addCase(deleteContactUsAsync.rejected, (state, action) => {
        state.deleteContactUsLoading = false;
        state.deleteContactUsError = action.payload;
      });
  },
});

export const { clearDeleteContactUs } = contactusSlice.actions;
export default contactusSlice;
