import PropTypes from "prop-types";

import { Box } from "@material-ui/core";
import KelatiLogo from "../assets/images/logo.png";

Logo.propTypes = {
  sx: PropTypes.object,
};

export default function Logo({ sx }) {
  return (
    <Box sx={{ width: 100, height: 100, ...sx }}>
      <img src={KelatiLogo} alt="Kelati Logo" />
    </Box>
  );
}
