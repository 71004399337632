export const enLocales = {
  kelatiAdmin: "Kelati Admin",

  url: {
    brandList: "Brand: List",
    mainCategoryList: "Main Category: List",
    categoryList: "Category: List",
    subCategoryList: "Sub Category: List",
    productList: "Product: List",
    inventoryList: "Inventory: List",
    variationList: "Variation: List",
    variationOptionList: "Variation Option: List",
    userList: "User: List",
    groupList: "Group: List",
    permissionList: "Permission: List",
    contactUsList: "Contact Us: List",
    orderList: "Order: List",
    dashboardList: "Dashboard: App",
    requestItemList: "Requested Items: List",
  },

  sidebar: {
    general: "General",
    dashboard: "Dashboard",
    products: "Products",
    orders: "Orders",
    inventory: "Inventory",
    productManagement: "Product Management",
    userManagement: "User Management",
    brands: "Brands",
    mainCategories: "Main Categories",
    categories: "Categories",
    subCategories: "Sub Categories",
    variations: "Variations",
    variationOptions: "Variation Options",
    users: "Users",
    groups: "Groups",
    permissions: "Permissions",
    contactUs: "Contact Us",
    requestedItems: "Requested Items",
  },

  brand: {
    list: "Brand List",
    pluralize: "Brands",
    createBrand: "Create Brand",
    updateBrand: "Update Brand",
    deleteBrand: "Delete Brand",
    searchBrand: "Search Brand",
    tableColumns: {
      brandName: "Brand name",
      brandImage: "Brand image",
      brandDescription: "Brand description",
    },
  },

  contactUs: {
    list: "Contact Us List",
    pluralize: "Contact Us",
    searchContactUs: "Search Contact Us",
    tableColumns: {
      fullName: "Full name",
      email: "Email",
      phone: "Phone",
      message: "Message",
    },
  },

  requestItem: {
    list: "Requested Items List",
    pluralize: "Requested Items",
    searchRequestItem: "Search Requested Items",
    tableColumns: {
      productName: "Product name",
      phoneNumber: "Phone number",
      email: "Email",
      productDescription: "Product description",
      status: "Status",
      requestItemImage: "Request item image",
    },
  },

  mainCategory: {
    list: "Main Category List",
    pluralize: "Main Categories",
    createMainCategory: "Create Main Category",
    updateMainCategory: "Update Main Category",
    deleteMainCategory: "Delete Main Category",
    searchMainCategory: "Search Main Category",
    tableColumns: {
      mainCategoryName: "Main category name",
      mainCategoryImage: "Main category image",
      mainCategoryDescription: "Main category description",
    },
  },

  category: {
    list: "Category List",
    pluralize: "Categories",
    createCategory: "Create Category",
    updateCategory: "Update Category",
    deleteCategory: "Delete Category",
    searchCategory: "Search Category",
    tableColumns: {
      categoryName: "Category name",
      categoryImage: "Category image",
      categoryDescription: "Category description",
    },
  },

  subCategory: {
    list: "Sub Category List",
    pluralize: "Sub Categories",
    createSubCategory: "Create Sub Category",
    updateSubCategory: "Update Sub Category",
    deleteSubCategory: "Delete Sub Category",
    searchSubCategory: "Search Sub Category",
    tableColumns: {
      subCategoryName: "Sub category name",
      subCategoryImage: "Sub category image",
      subCategoryDescription: "Sub category description",
      howToUse: "How to use",
      ingredients: "Ingredients",
    },
  },

  product: {
    list: "Product List",
    pluralize: "Products",
    createProduct: "Create Product",
    updateProduct: "Update Product",
    deleteProduct: "Delete Product",
    searchProduct: "Search Product",
    tableColumns: {
      productName: "Product name",
      productImage: "Product image",
      productDescription: "Product description",
      howToUse: "How to use",
      ingredients: "Ingredients",
    },
  },

  order: {
    list: "Order List",
    pluralize: "Orders",
    searchOrder: "Search Order",
    tableColumns: {
      orderNumber: "Order number",
      orderStatus: "Order status",
      orderDate: "Order date",
      orderTotal: "Order total",
      orderPaymentStatus: "Order payment status",
      orderPaymentMethod: "Order payment method",
      orderCustomer: "Order customer",
    },
  },

  inventory: {
    list: "Inventory List",
    pluralize: "Inventories",
    searchInventory: "Search Inventory",
    tableColumns: {
      inventoryName: "Inventory name",
      inventoryImage: "Inventory image",
      inventoryDescription: "Inventory description",
      unitPrice: "Unit price",
      totalStock: "Total stock",
      totalPrice: "Total price",
    },
  },

  variation: {
    list: "Variation List",
    pluralize: "Variations",
    createVariation: "Create Variation",
    updateVariation: "Update Variation",
    deleteVariation: "Delete Variation",
    searchVariation: "Search Variation",
    tableColumns: {
      variationName: "Variation name",
      variationDescription: "Variation description",
    },
  },

  variationOption: {
    list: "Variation Option List",
    pluralize: "Variation Options",
    createVariationOption: "Create Variation Option",
    updateVariationOption: "Update Variation Option",
    deleteVariationOption: "Delete Variation Option",
    searchVariationOption: "Search Variation Option",
    tableColumns: {
      variationOptionName: "Variation option name",
      variationOptionDescription: "Variation option description",
    },
  },

  user: {
    list: "User List",
    pluralize: "Users",
    createUser: "Create User",
    updateUser: "Update User",
    deleteUser: "Delete User",
    searchUser: "Search User",
    tableColumns: {
      userFullName: "User full name",
      userName: "User name",
      userEmail: "User email",
      userRole: "User role",
      userPhoneNumber: "User phone number",
      userGroups: "User groups",
      userStatus: "Status",
    },
  },

  permissions: {
    list: "Permission List",
    pluralize: "Permissions",
    searchPermission: "Search Permission",
    tableColumns: {
      permissionName: "Permission name",
      permissionCodeName: "Permission code name",
    },
  },

  groups: {
    list: "Group List",
    pluralize: "Groups",
    createGroup: "Create Group",
    updateGroup: "Update Group",
    deleteGroup: "Delete Group",
    searchGroup: "Search Group",
    tableColumns: {
      groupName: "Group name",
      groupOption: "Group option",
      groupDescription: "Group description",
      groupPermissions: "Group permissions",
    },
  },

  common: {
    dashboard: "Dashboard",
    tableColumns: {
      slug: "Slug",
      createdDate: "Created date",
      updatedDate: "Updated date",
      featured: "Featured",
      actions: "Actions",
    },
    searchBy: "Search by",
  },
};
