import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../utils/axios";

const INITIAL_STATE = {
  fetchPermissionsLoading: false,
  fetchPermissionsError: null,

  permissions: [],
  pagination: {
    previous: null,
    next: null,
    count: 0,
  },
};

export const fetchPermissionsAsync = createAsyncThunk(
  "permission/fetchPermissionsAsync",
  async ({ all, page, payload }, { rejectWithValue }) => {
    try {
      const { data } = await api.get("/permissions/", {
        params: !all
          ? {
              page,
              name__icontains: payload,
              codename__icontains: payload,
              content_type__icontains: payload,
            }
          : {
              all: true,
            },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const permissionSlice = createSlice({
  name: "permission",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPermissionsAsync.pending, (state) => {
        state.fetchPermissionsLoading = true;
      })
      .addCase(fetchPermissionsAsync.fulfilled, (state, action) => {
        const { results, previous, next, count } = action.payload;
        state.fetchPermissionsLoading = false;
        state.permissions = action.meta.arg.all ? action.payload : results;
        state.pagination = {
          previous,
          next,
          count,
        };
      })
      .addCase(fetchPermissionsAsync.rejected, (state, action) => {
        state.fetchPermissionsLoading = false;
        state.fetchPermissionsError = action.payload;
      });
  },
});

export default permissionSlice;
