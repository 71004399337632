import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../utils/axios";

const INITIAL_STATE = {
  fetchProductCombinationLoading: false,
  fetchProductCombinationError: null,

  updateProductCombinationLoading: false,
  updateProductCombinationSuccess: false,
  updateProductCombinationError: null,

  deleteProductCombinationLoading: false,
  deleteProductCombinationSuccess: false,
  deleteProductCombinationError: null,

  productCombinations: [],
  productCombination: null,
  pagination: {
    previous: null,
    next: null,
    count: 0,
  },
};

export const fetchProductCombinationsAsync = createAsyncThunk(
  "productCombination/fetchProductCombinationsAsync",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get("/product-combinations/");
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchProductCombinationsByProductIdAsync = createAsyncThunk(
  "productCombination/fetchProductCombinationsByProductIdAsync",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await api.get(`/product-combinations/${id}/admin`);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateProductCombinationAsync = createAsyncThunk(
  "productCombination/updateProductCombinationAsync",
  async ({ sku, payload }, { rejectWithValue }) => {
    try {
      const { data } = await api.put(`/product-combinations/${sku}/`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteProductCombinationAsync = createAsyncThunk(
  "productCombination/deleteProductCombinationAsync",
  async (id, { rejectWithValue }) => {
    try {
      await api.delete(`/product-combinations/${id}/`);
      return id;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const productCombinationSlice = createSlice({
  name: "productCombination",
  initialState: INITIAL_STATE,
  reducers: {
    clearFetchProductCominationsByProductId: (state) => {
      state.fetchProductCombinationLoading = false;
      state.fetchProductCombinationError = null;
      state.productCombinations = [];
    },

    clearUpdateProductCombination: (state) => {
      state.updateProductCombinationLoading = false;
      state.updateProductCombinationSuccess = false;
      state.updateProductCombinationError = null;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchProductCombinationsAsync.pending, (state) => {
        state.fetchProductCombinationLoading = true;
        state.fetchProductCombinationError = null;
      })
      .addCase(fetchProductCombinationsAsync.fulfilled, (state, action) => {
        const { results, previous, next, count } = action.payload;
        state.fetchProductCombinationLoading = false;
        state.fetchProductCombinationError = null;
        state.productCombinations = results;
        state.pagination = {
          previous,
          next,
          count,
        };
      })
      .addCase(fetchProductCombinationsAsync.rejected, (state, action) => {
        state.fetchProductCombinationLoading = false;
        state.fetchProductCombinationError = action.payload;
      })

      .addCase(fetchProductCombinationsByProductIdAsync.pending, (state) => {
        state.fetchProductCombinationLoading = true;
        state.fetchProductCombinationError = null;
      })
      .addCase(
        fetchProductCombinationsByProductIdAsync.fulfilled,
        (state, action) => {
          state.fetchProductCombinationLoading = false;
          state.fetchProductCombinationError = null;
          state.productCombinations = action.payload;
        }
      )
      .addCase(
        fetchProductCombinationsByProductIdAsync.rejected,
        (state, action) => {
          state.fetchProductCombinationLoading = false;
          state.fetchProductCombinationError = action.payload;
        }
      )

      .addCase(updateProductCombinationAsync.pending, (state) => {
        state.updateProductCombinationLoading = true;
        state.updateProductCombinationSuccess = false;
        state.updateProductCombinationError = null;
      })
      .addCase(updateProductCombinationAsync.fulfilled, (state, action) => {
        state.updateProductCombinationLoading = false;
        state.updateProductCombinationSuccess = true;
        state.updateProductCombinationError = null;
        state.productCombinations = state.productCombinations.map(
          (productCombination) =>
            productCombination.id === action.payload.id
              ? action.payload
              : productCombination
        );
      })
      .addCase(updateProductCombinationAsync.rejected, (state, action) => {
        state.updateProductCombinationLoading = false;
        state.updateProductCombinationSuccess = false;
        state.updateProductCombinationError = action.payload;
      })

      .addCase(deleteProductCombinationAsync.pending, (state) => {
        state.deleteProductCombinationLoading = true;
        state.deleteProductCombinationSuccess = false;
        state.deleteProductCombinationError = null;
      })
      .addCase(deleteProductCombinationAsync.fulfilled, (state, action) => {
        state.deleteProductCombinationLoading = false;
        state.deleteProductCombinationSuccess = true;
        state.deleteProductCombinationError = null;
        state.productCombinations = state.productCombinations.filter(
          (productCombination) => productCombination.id !== action.meta.arg
        );
      })
      .addCase(deleteProductCombinationAsync.rejected, (state, action) => {
        state.deleteProductCombinationLoading = false;
        state.deleteProductCombinationSuccess = false;
        state.deleteProductCombinationError = action.payload;
      });
  },
});

export const {
  clearUpdateProductCombination,
  clearFetchProductCominationsByProductId,
} = productCombinationSlice.actions;
export default productCombinationSlice;
