import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../utils/axios";
import { checkError } from "../../../utils/checkError";

const INITIAL_STATE = {
  fetchBrandsLoading: false,
  fetchBrandsError: null,

  createBrandLoading: false,
  createBrandSuccess: false,
  createBrandError: null,

  updateBrandLoading: false,
  updateBrandSuccess: false,
  updateBrandError: null,

  isFeaturedBrandLoading: false,
  isFeaturedBrandSuccess: false,
  isFeaturedBrandError: null,

  deleteBrandLoading: false,
  deleteBrandSuccess: false,
  deleteBrandError: null,

  brands: [],
  pagination: {
    previous: null,
    next: null,
    count: 0,
  },
};

export const fetchBrandsAsync = createAsyncThunk(
  "brand/fetchBrandsAsync",
  async ({ all, page, payload }, { rejectWithValue }) => {
    try {
      const { data } = await api.get("/brands/", {
        params: !all
          ? {
              page: page,
              description__icontains: payload,
              name__icontains: payload,
            }
          : {
              all: true,
            },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createBrandAsync = createAsyncThunk(
  "brand/createBrandAsync",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await api.post("/brands/", payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateBrandAsync = createAsyncThunk(
  "brand/updateBrandAsync",
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const { data } = await api.put(`/brands/${id}/`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateIsFeaturedOnBrandAsync = createAsyncThunk(
  "brand/updateIsFeaturedOnBrandAsync",
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const { data } = await api.patch(`/brands/${id}/`, payload);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteBrandAsync = createAsyncThunk(
  "brand/deleteBrandAsync",
  async (payload, { rejectWithValue }) => {
    try {
      await api.delete(`/brands/${payload}`);
      return payload;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const brandSlice = createSlice({
  name: "brand",
  initialState: INITIAL_STATE,
  reducers: {
    clearCreateBrand: (state) => {
      state.createBrandLoading = false;
      state.createBrandSuccess = false;
      state.createBrandError = null;
    },
    clearUpdateBrand: (state) => {
      state.updateBrandLoading = false;
      state.updateBrandSuccess = false;
      state.updateBrandError = null;
    },
    clearIsFeaturedBrand: (state) => {
      state.isFeaturedBrandLoading = false;
      state.isFeaturedBrandSuccess = false;
      state.isFeaturedBrandError = null;
    },
    clearDeleteBrand: (state) => {
      state.deleteBrandLoading = false;
      state.deleteBrandSuccess = false;
      state.deleteBrandError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBrandsAsync.pending, (state, action) => {
        state.fetchBrandsLoading = true;
      })
      .addCase(fetchBrandsAsync.fulfilled, (state, action) => {
        const { results, previous, next, count } = action.payload;
        state.fetchBrandsLoading = false;
        state.brands = action.meta.arg.all ? action.payload : results;
        state.pagination = {
          previous,
          next,
          count,
        };
      })
      .addCase(fetchBrandsAsync.rejected, (state, action) => {
        state.fetchBrandsError = action.payload;
      })

      .addCase(createBrandAsync.pending, (state, action) => {
        state.createBrandLoading = true;
      })
      .addCase(createBrandAsync.fulfilled, (state, action) => {
        state.createBrandSuccess = true;
        state.brands.unshift(action.payload);
        state.pagination.count += 1;
      })
      .addCase(createBrandAsync.rejected, (state, action) => {
        const { response } = action.payload;
        state.createBrandError = checkError(response);
      })

      .addCase(updateBrandAsync.pending, (state, action) => {
        state.updateBrandLoading = true;
      })
      .addCase(updateBrandAsync.fulfilled, (state, action) => {
        state.updateBrandSuccess = true;
        state.brands = state.brands.map((brand) =>
          brand.id === action.payload.id ? action.payload : brand
        );
      })
      .addCase(updateBrandAsync.rejected, (state, action) => {
        const { response } = action.payload;
        state.updateBrandError = checkError(response);
      })

      .addCase(updateIsFeaturedOnBrandAsync.pending, (state, action) => {
        state.isFeaturedBrandLoading = true;
      })
      .addCase(updateIsFeaturedOnBrandAsync.fulfilled, (state, action) => {
        state.isFeaturedBrandSuccess = true;
        state.brands = state.brands.map((brand) =>
          brand.id === action.payload.id ? action.payload : brand
        );
      })
      .addCase(updateIsFeaturedOnBrandAsync.rejected, (state, action) => {
        state.isFeaturedBrandError = action.payload;
      })

      .addCase(deleteBrandAsync.pending, (state, action) => {
        state.deleteBrandLoading = true;
      })
      .addCase(deleteBrandAsync.fulfilled, (state, action) => {
        state.deleteBrandSuccess = true;
        state.brands = state.brands.filter(
          (brand) => brand.id !== action.meta.arg
        );
        state.pagination.count -= 1;
      })
      .addCase(deleteBrandAsync.rejected, (state, action) => {
        state.deleteBrandError = action.payload;
      });
  },
});

export const {
  clearCreateBrand,
  clearUpdateBrand,
  clearIsFeaturedBrand,
  clearDeleteBrand,
} = brandSlice.actions;
export default brandSlice;
